
.containerHeader_BackTitle{
  position: relative;
}
.container_TitleRoomId{
  font-weight: var(--fw-Medium);
  font-size: var(--fs-xl);
  color: var(--text_Principal_Light);
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  width: 100dvw;
  top: -80px;
}

.container_DetailRoomBien{
  margin: 3rem 0rem;
}

.FormDetailRoomBien{
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.legendeDetailBien{
font-size: var(--fs-xl);
font-weight: var(--fw-Black);
color: var(--text_Principal_Dark);
margin-bottom: 2rem;
&::after{
  position: absolute;
  bottom: -2rem;
  left: 0dvw;
  content: " ";
  width: 100%;
  height: 0.05rem;
  padding: 0rem 0rem;
  background-color: var(--form-borderColor);
}
}

.grid{
  width: inherit;
  display: grid;
  grid-template-columns: repeat(2, 45dvw);
  gap: 16px;

}
.adressePostalRowInGrid
{grid-column: -1 / 1;}