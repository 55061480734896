@import "../../style/functionCSS.scss";

.PresentationUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.PresentationUser_avatar {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 5rem;
  width: 5rem;
  margin-bottom: 0.5rem;
  border-radius: 3rem;
  padding: 0rem 0rem 0rem 0.5rem;
}

.PresentationUser_prenom,
.PresentationUser_nom {
  color: var(--second);
  margin: 0rem;
  text-align: center;
  word-break: break-all;
  padding: 0 1em;
}

.PresentationUser_nom {
  font-size: px-to-rem(32px);
  font-weight: 900;
}

.PresentationUser_prenom {
  font-weight: 300;
  font-size: px-to-rem(34px);
}
