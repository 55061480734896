@import "../../../style/functionCSS.scss";

.container_Modal_Alerte {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(104, 99, 99, 0.69);
  height: 100%;
  width: 100%;
  z-index: 20000;
  /* display: none; */
  align-items: center;
  justify-content: center;

  .modal_Alerte {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: px-to-rem(250px);
    height: 90px;
    border: #aec0b7 1px solid;
    background-color: white;
    border-radius: 35px;
    box-shadow: 0px 3px 6px 1px #00000029;
    padding: 1rem;
  }

  img {
    position: absolute;
    top: -25px;
    width: 2.8rem;
    height: 3rem;
  }

  h1 {
    color: #535353;
    font-size: px-to-rem(20px);
    font-weight: var(--fw-ExtraBold);
  }
}
