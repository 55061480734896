@import "../../../../style/functionCSS.scss";

.container_Modal_InfoLight {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(104, 99, 99, 0.69);
  height: 100%;
  width: 100%;
  z-index: 20000;
  /* display: none; */
  align-items: center;
  justify-content: center;

  .modal_InfoLight {
    position: relative;
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7.5rem;
    width: 21rem;
    border: #aec0b7 1px solid;
    background-color: white;
    border-radius: 35px;
    box-shadow: 0px 3px 6px 1px #00000029;
  }
  .button_InfoLight {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .iconFill{
    fill: var(--first);
  }
  .text_InfoLight {
    text-align: center;
    color: var(--text_Principal_Dark);
    font-size: var(--fs-lg);
    font-weight: var(--fw-Regular);
  }
}
