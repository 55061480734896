@import "../../style/functionCSS";

.container_Title {
  margin-bottom: var(--marginBottom);
}

.bk_doc {
  background: var(--background-doc);
}

.container_Documents_Edit {
  display: flex;
  padding: 6rem 0rem 0rem;
  height: 100dvh;
  width: 100vw;
  overflow: scroll;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .buttons_Folders_Files_Edit {
    display: grid;
    grid-template-columns: 50% 50%;
    align-content: center;
    justify-items: center;
    align-items: center;
    margin: 7rem 1rem 7rem 1rem;

    .container_checkbox_folder_file_card_Edit {
      position: relative;
      width: 100%;

      .styledButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        gap: 0.5rem;

        i {
          font-size: 4rem;
          color: var(--accent);
        }

        span {
          text-align: center;
          color: var(--third);
        }
      }

      .hiddenCheckbox {
        position: absolute;
        top: 4rem;
        left: 80%;

        &[type="checkbox"] {
          appearance: none;
          background-color: white;
          width: px-to-rem(20px);
          height: px-to-rem(20px);
          border-radius: 50%;
          border: 2px solid var(--border-neutre);
        }

        &[type="checkbox"]:checked {
          background-color: var(--first);
        }
      }
    }
  }
}
