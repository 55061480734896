.slider {
  .container_commune_rayon_Ref {
    display: flex;
    justify-content: center;
    .rangeslider-horizontal {
      height: 8px;
      width: 12rem;
      border-radius: 10px;
    }
    .rangeslider__fill {
      background-color: var(--second);
    }
    .rangeslider__handle {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      &:after {
        display: none;
      }
    }
  }
}
