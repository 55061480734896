.container_Formulaire_User {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  .edit_User {
    position: relative;
    display: flex;
    justify-content: center;
    .editAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 1rem;
      height: 1rem;
      text-align: center;
      top: 4rem;
      outline: var(--second) 2px solid;
      background: white;
      border-radius: 50%;
    }
  }

  .modal_edition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.disabled {
  overflow: hidden;
}
