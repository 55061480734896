// Mixin pour une animation de fade-in
@mixin lowerScale($duration: 0.5s) {
  animation: scale $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}
@mixin like($duration: 0.5s) {
  animation: Like $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes Like {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }
  &:hover,
  &:active,
  &:focus {
    fill: var(--first);
    stroke: var(--first);
  }
}

@mixin slide-left($duration: 0.5s) {
  animation: slide-left $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
}
@mixin slide-right($duration: 0.5s) {
  animation: slide-right $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-right {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
@mixin slide-down($duration: 0.05s) {
  animation: slide-down $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-down {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(10%);//Attention si modification faire une automatisation car cela risque de toucher le visuel de la page 'immo' sur la partie Search
    }
  }
}
@mixin slide-up($duration: 0.05s) {
  animation: slide-up $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-up {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
}

@mixin slide-half-down($translateStart: -50%, $translateEnd: 0%, $duration: 0.5s) {
  animation: slide-half-down $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-half-down {
    0% {
      transform: translateY($translateStart);
    }
    100% {
      transform: translateY($translateEnd);
    }
  }
}

@mixin slide-half-up($translateStart: 0%, $translateEnd: -65%, $duration: 0.5s) {
  animation: slide-half-up $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-half-up {
    0% {
      transform: translateY($translateStart);
    }
    100% {
      transform: translateY($translateEnd);
    }
  }
}
@mixin slide-card-immo-up($translateStart: 16rem, $translateEnd: 5rem, $duration: 0.5s) {
  animation: slide-card-immo-up $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-card-immo-up {
    0% {
      margin-top: $translateStart;
    }
    100% {
      margin-top: $translateEnd;
    }
  }
}
@mixin slide-card-immo-reset($translateStart: 8rem, $translateEnd: 13.5rem, $duration: 0.5s) {
  animation: slide-card-immo-reset $duration cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-card-immo-reset {
    0% {
      margin-top: $translateStart;
    }
    100% {
      margin-top: $translateEnd;
    }
  }
}

@mixin fade-in($duration: 0.5s) {
  opacity: 0;
  animation: fade-in-animation $duration ease-in-out forwards;

  @keyframes fade-in-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
// Créez une nouvelle mixin qui combine slide-left et fade-in
@mixin slide-left-fade-in($duration: 0.5s) {
  animation: slide-left-fade-in-animation $duration
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-left-fade-in-animation {
    0% {
      transform: translateX(400px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

// Mixin pour une animation de rotation
@mixin rotate($degrees: 360deg, $duration: 1s) {
  transform: rotate(0);
  animation: rotate-animation $duration ease-in-out forwards;

  @keyframes rotate-animation {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate($degrees);
    }
  }
}
// Mixin pour une animation de rotation
@mixin rotateInfini($degrees: 360deg, $duration: 10s) {
  transform: rotate(0);
  animation: rotate-animation $duration ease-in-out infinite;

  @keyframes rotate-animation {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate($degrees);
    }
  }
}

// Mixin pour une animation de rebond
@mixin bounce($distance: 20px, $duration: 0.5s) {
  transform: translateY(0);
  animation: bounce-animation $duration ease-in-out infinite alternate;

  @keyframes bounce-animation {
    to {
      transform: translateY(-$distance);
    }
  }
}
