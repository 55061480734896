.FormPhone {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);

}

.FormPhone_input {
    display: flex;
    width: 100%;
    min-height: var(--form-baseHeight);
    color: var(--textColor);
    background: var(--backgroundColor);
    border-radius: var(--form-borderRadius);
    padding: var(--form-paddingY) var(--form-paddingX);
    box-sizing: border-box;
    border: var(--form-borderWidth) solid var(--borderColor);

    &:focus-visible {
        --borderColor: var(--form-outlineColor);
        outline: var(--form-outlineWidth) solid var(--form-outlineColor);
    }

    &::placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
    }
}

.FormPhone-invalid {
    .FormPhone_input {
        --borderColor: var(--form-invalidColor);
    }
}

.FormPhone-disabled {
    .FormPhone_input {
        cursor: default;
        --textColor: var(--form-textColor-disabled);
        --backgroundColor: var(--form-backgroundColor-disabled);
    }
}