@import "../../../style/functionCSS";

.container_edit_del_file_folder {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  margin-bottom: 0.6rem;
  background: #f9f9f987;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
  padding: 1rem 0rem;

  .options_edit {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.5rem;
    width: px-to-rem(144px);
    height: px-to-rem(37px);
    background-color: var(--first);
    border-radius: px-to-rem(25px);
    border: var(--first) px-to-rem(1px) solid;
    color: white;
    font-weight: 700;

    p {
      margin: 0.5rem;
      gap: 0.5rem;
      gap: 0.5rem;

      .icon_button_plus {
        color: #535353;
        width: 30px;
        height: 30px;
        font-size: 26px;
        font-weight: 700;
        background-color: white;
        border: #535353 1px solid;
        border-radius: 50%;
        margin: 0rem;
      }
    }
  }
}