@import "../../../style/functionCSS.scss";
@import "../../../style/base/variables.scss";

.FormSearch {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);

    width: auto;
    height: auto;
    color: var(--textColor);

    .FormSearch__control {
        width: auto;
        height: auto;
        min-height: calc(var(--form-baseHeight) * 1.2);
        border-radius: calc(var(--form-baseHeight) * 1.2 / 2);
        display: flex;
        align-items: center;
        align-content: center;
        background: var(--backgroundColor);
        border: var(--form-borderWidth) solid var(--borderColor);
    }

    .FormSearch__value-container {
        padding: 0 calc(35px + var(--form-paddingX));
    }

    .FormSearch__control--is-focused {
        --borderColor: var(--form-outlineColor);
        box-shadow: 0 0 0 var(--form-outlineWidth) var(--form-outlineColor);
    }

    .FormSearch__placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
        margin: 0;
    }

    .FormSearch__indicators {
        display: none;
    }
}