@import "../../style/mixins/media-queries.scss";
@import "../../style/functionCSS.scss";
@import "../../style/base/variables.scss";

// :root {
//   --PageHeader-height: 8rem;
// }
.PageHeader {
  height: var(--PageHeader-height);
}
.PageHeader_header {
  position: relative;
  height: var(--PageHeader-height);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  .title {
    display: flex;
    justify-content: center;
    width: 100%;
    color: var(--third);
    font-size: px-to-rem(40px);
    font-weight: var(--fw-Bold);
    padding-bottom: 3rem;
  }

  .titleRotate {
    position: absolute;
    top: 4dvh;
    right: 4dvw;
    text-align: center;
    // transform: translate(50%, 50%);
    display: flex;
    justify-content: flex-end;
    color: var(--third);
    font-size: px-to-rem(18px);
    font-weight: var(--fw-Bold);
    rotate: -90deg;
    .textRotate{
      width: max-content;
    }
  }
}
._base {
  background-image: url("../../img/header_Jaune.svg");
}
._param{
  background-image: url("../../img/header_Vert.svg");
}
._bien {
  background-image: url("../../img/header_Bien.svg");
}

.container_Project_Header {
  position: fixed;
  top: 4.5dvh;
  align-items: center;
  background: rgba(249, 249, 249, 0.5294117647);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);

  display: flex;
  flex-direction: row;
  // justify-content: center;
  background: rgb(255 255 255 / 21%);
  border: 1px solid var(--border-accent);
  border-radius: 1.5rem;
  margin: 2rem;
  width: 85%;
  max-width: 300px;
  height: 5rem;
  z-index: 1;
  box-shadow: var(--box-shadow-big);
  transition: 1s ease 0s;
  z-index: 1001;

  &:hover {
    box-shadow: 0px 10px 10px rgba(56, 54, 54, 0.4);
  }

  .card_Project {
    position: relative;
    margin: 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h1 {
        margin: 0rem;
      }
    }

    i {
      position: absolute;
      right: 1rem;
      top: 1rem;

      @include S-mobile-M {
        right: 0rem;
      }

      @include M-mobile-L {
        right: 0rem;
      }

      @include L-mobile-Tablet {
        right: 0rem;
      }

      @include Tablet-and-More {
        right: 0rem;
      }
    }
  }

  img {
    width: 64px;
    margin: 0.5rem;
  }

  h1 {
    font-size: px-to-rem(23px);
    font-weight: var(--fw-Bold);
    color: var(--second);
    margin: 0rem;
    margin-top: 1rem;
  }

  p {
    font-size: px-to-rem(17px);
    font-weight: var(--fw-Light);
    color: var(--second);
  }
}
