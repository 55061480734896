.select {
  font-weight: Bold;
  font-size: 1.188rem;
  color: #ffffff;
  border-radius: 25px;
  width: 10.7rem;
  height: 2.313rem;
  margin-bottom: 1.063rem;
  margin-top: 0.5rem;
  cursor: pointer;
}
.loginMP {
  padding-left: 1.25rem;
  background-color: transparent;
  border: 1px solid var(--first);
}

.filter_input {
  font-weight: 400;
  color: var(--text_Secondaire_Fade);
  border-radius: 25px;
  height: 2.313rem;
  width: 8.7rem;
  margin-bottom: 1.063rem;
  margin-top: 0.5rem;
  padding: 0rem 1.25rem;
  border: 1px solid #e3e3e3;
  box-shadow: var(--boxShadow);
  text-align: start;
  &::placeholder {
    color: var(--text_Secondaire_Fade);
  }
  &:focus-visible {
    outline: 2px solid var(--first);
  }
}
/*
  .filter_input {
    font-weight: 300;
    border-radius: 13px;
    border: 1px solid var(--not-valid);
    background-color: var(--not-valid);
    height: 2.313rem;
    width: 8.7rem;
    margin-bottom: 1.063rem;
    margin-top: 0.5rem;
    padding: 0rem 1.25rem;
    text-align: start;
    box-shadow: var(--boxShadow);
    &::placeholder {
      color: var(--border-neutre);
    }
    &:focus-visible {
      outline: 2px solid var(--first);
    }
  }
    */
