.basic-select {
    .css-b62m3t-container {
        border-radius: 3rem;
        margin: 1rem 0rem;
        width: auto;
        height: auto;

        .css-13cymwt-control {
            width: auto;
            height: auto;
            padding-left: 0.5rem;
            border-radius: 3rem;
            display: flex;
            align-items: center;
            align-content: center;

            .css-3w2yfm-ValueContainer {
                padding: 6px 20px;
            }
        }

        .css-t3ipsp-control {
            border-radius: 3rem;
            border: var(--first) 2px solid;
            box-shadow: var(--first) 2px solid;
            height: 0px;
            display: flex;
            width: auto;
            height: auto;
            align-items: center;
            align-content: center;

            &:hover {
                border: var(--first) 2px solid;
                box-shadow: var(--first) 2px solid;
            }

            .css-3w2yfm-ValueContainer {
                padding: 6px 20px;
                width: auto;
                height: auto;
            }
        }

        .css-1u9des2-indicatorSeparator {
            display: none;
            width: auto;
            height: auto;
        }
    }

    .dropdown_interne {
        left: -2rem;
        width: 15rem;
    }
}