#select_toggleBig {
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    align-items: center;
    height: 2rem;
    border-radius: 35px;
    border: 2px solid var(--second);
    list-style: none;
    margin: 0rem;
    padding: 0rem 0.3rem;

    .nav-link {
      align-items: center;
      justify-content: center;
      align-content: center;
      margin: 0rem 0.5rem;
      color: var(--text_Secondaire_Fade);
      font-weight: bold;
    }

    .activeToggle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 35px;
      transform: scale(1.15);
      padding: 0.1rem 0.5rem;
      background-color: var(--second);

      .nav-link {
        display: flex;
        color: var(--third);
        align-items: center;
        justify-content: center;
        align-content: center;
        margin: 0rem;
        padding: 0.1rem 0.5rem;
      }
    }
  }