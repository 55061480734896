.container_slider_2buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  .MuiBox-root {
    .MuiSlider-rail {
      display: block;
      position: absolute;
      border-radius: inherit;
      background-color: currentColor;
      opacity: 0.38;
      width: 100%;
      height: inherit;
      top: 50%;
      color: #ebbc02a3;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .MuiSlider-track {
      left: 3%;
      width: 47%;
      color: var(--first);
    }
    .MuiSlider-thumb::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      border: 2px solid var(--first);
      background-color: white;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .Mui-active {
      box-shadow: 0px 0px 0px 14px var(--firstTransparent);
    }
  }
  .estimativeValue {
    color: var(--first);
  }
  .container_littleInfo {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    .littleInfo {
      font-size: 50%;
    }
  }
}
