@import "../../../../style/functionCSS.scss";

.options_container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 2fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: inherit;

  .info_option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    .icon {
      width: px-to-rem(40px);
    }

    div {
      font-size: px-to-rem(14px);
      color: var(--second);
      font-weight: 800;
    }
  }

  .inputs_min_max {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    h3 {
      font-weight: 400;
      font-size: px-to-rem(17px);
      margin: 0rem;
      color: var(--second);
    }

    div {
      position: relative;
      width: max-content;

      input {
        padding: 5px 8px;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        appearance: none;
        text-align: start;
        cursor: pointer;

        &:focus-visible {
          outline: 2px solid var(--first);
          border-radius: 3rem;
          padding-left: 0.5rem;
          height: 2rem;

          &:not {
            border: 2px solid var(--first) !important;
          }
        }
      }

      span {
        position: absolute;
        color: var(--second);
        font-weight: 900;
        top: 45%;
        right: 10%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
