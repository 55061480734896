@import "../../style/functionCSS.scss";
@import"../../style/fontSize.scss";

.container_New_Projet {
  position: relative;
  top: 2rem;
  height: calc(100dvh - var(--Footer-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .titre {
    margin-bottom: var(--marginBottom);

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .p_Second {
        color: var(--second);
        margin: 0rem;
        font-size: px-to-rem(41px);
        font-weight: var(--fw-Black);
        text-align: center;
      }

      .p_First {
        color: var(--first);
        margin: -0.5rem 0rem;
        font-size: px-to-rem(59px);
        font-weight: var(--fw-Black);
        text-align: center;
      }
    }
  }

  .buttons_New_Projets {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: var(--marginBottom);
    gap: 2rem;
  }

}