.FormRadio {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);
    --borderWidth: var(--form-borderWidth);

    display: flex;
    align-items: center;
    width: 100%;
    min-height: var(--form-baseHeight);
    color: var(--textColor);
    background: var(--backgroundColor);
    box-sizing: border-box;

    &:focus-visible {
        --borderColor: var(--form-outlineColor);
        outline: var(--form-outlineWidth) solid var(--form-outlineColor);
    }

    &::placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
    }
}

.FormRadio-invalid {
    --borderColor: var(--form-invalidColor);
}

.FormRadio-disabled {
    cursor: default;
    --textColor: var(--form-textColor-disabled);
    --backgroundColor: var(--form-backgroundColor-disabled);
}

.FormRadio_input {
    appearance: none;
    border-radius: 50%;
    display: block;
    min-width: calc(var(--form-baseHeight) * 0.75);
    height: calc(var(--form-baseHeight) * 0.75);

    border: var(--borderWidth) solid var(--borderColor);
    transition: 0.2s all linear;
    margin-right: 5px;

    &:checked {
        background-color: var(--first);
    }
}

.FormRadio_label {
    margin: var(--form-paddingY) 0;
}