.FormAddon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.FormAddon-left {
    left: 7px;
}

.FormAddon-right {
    right: 7px;
}