@import "./../../../style//functionCSS";
@import "./../../../style//base/variables";

.container_Modal_Grid {
  position: fixed;
  top: 50dvh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  max-width: 23rem;
  .modal-content {
    display: flex;
    border-radius: 19px;
    border: 1px solid var(--accent);
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: fit-content;
    .buttons_Modal {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: var(--accent);
      outline: var(--accent) 1px solid;
      border-radius: 19px 19px 0px 0px;
      width: 100%;
      .close-button {
        color: var(--cross);
        width: px-to-rem(43px);
        position: relative;
        right: 0rem;
      }
    }
    
    .title {
      font-size: var(--fs-lg);
      font-weight: Bold;
      text-align: center;
      padding: 0.5rem;
      margin: 0rem;
      width: 65%;
      color: var(--text_Principal_Light);
    }

    .justifyContentEnd {
      position: absolute;
      justify-content: flex-end;
      top: 2.5%;
      width: 90%;
    }
  }
}

.gridInfo {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 45%);
  gap: 10px;
  padding: 1rem;
}
.close {
  display: none;
}
