@import "../../style/functionCSS.scss";

:root {
  --ButtonV2-height-sm: #{px-to-rem(24px)};
  --ButtonV2-height-md: #{px-to-rem(38px)};
  --ButtonV2-height-lg: #{px-to-rem(54px)};
}

.ButtonV2 {
  --ButtonV2-textColor: var(--element-textColor);
  --ButtonV2-textColor-hover: ;
  --ButtonV2-backgroundColor: ;
  --ButtonV2-backgroundColor-hover: hsl(var(--element-textColor-hsl) / 0.1);
  --ButtonV2-borderColor: none;
  --ButtonV2-borderColor-hover: ;
  --ButtonV2-outlineWidth: var(--form-outlineWidth);
  --ButtonV2-outlineColor: var(--form-outlineColor);
  --ButtonV2-padding: 0 1em;
  --ButtonV2-height: var(--ButtonV2-height-md);
  --ButtonV2-borderRadius: calc(var(--ButtonV2-height) / 2);
  --ButtonV2-fontWeight: var(--fw-Regular);
  --ButtonV2-fontSize: ;
  --ButtonV2-boxShadow: ;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: inherit;
  font-size: var(--ButtonV2-fontSize);
  font-weight: var(--ButtonV2-fontWeight);
  color: var(--ButtonV2-textColor);
  background: var(--ButtonV2-backgroundColor);
  border: 1px solid var(--ButtonV2-borderColor);
  border-radius: var(--ButtonV2-borderRadius);
  box-shadow: var(--ButtonV2-boxShadow);
  height: var(--ButtonV2-height);
  padding: var(--ButtonV2-padding);

  &:hover {
    color: var(--ButtonV2-textColor-hover);
    background-color: var(--ButtonV2-backgroundColor-hover);
    border-color: var(--ButtonV2-borderColor-hover);
  }

  &:focus-within {
    outline: var(--ButtonV2-outlineWidth) solid var(--ButtonV2-outlineColor);
  }
}

.ButtonV2_icon {
  margin-right: 0.5em;
}

.ButtonV2-sm {
  --ButtonV2-height: var(--ButtonV2-height-sm);
}

.ButtonV2-lg {
  --ButtonV2-height: var(--ButtonV2-height-lg);
}

.ButtonV2-block {
  display: flex;
}

.ButtonV2-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.ButtonV2-noShadow {
  display: flex;
}

.ButtonV2-primary {
  --ButtonV2-boxShadow: var(--box-shadow-button);
}