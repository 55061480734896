@import "../../style/mixins/_animations.scss";
@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.container_Conseiller_Presentation {
  overflow-x: hidden;
}
.carte_Agence {
  
  margin: 0rem -10.5dvw 2rem;
  display: flex;
  align-items: center;
  height: 11.1875rem;
  background: var(--seven);
  justify-content: center;
  .icon_Agence {
    width: 98px;
    height: 79px;
    filter: drop-shadow(0px 4px 4px #0000003b);
  }
  .agence {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: px-to-rem(21px);
      font-weight: var(--fw-Bold);
      text-align: center;
      letter-spacing: 0px;
      color: var(--text_Principal_Dark);
      margin: 0rem;
    }
    .container_Adresse {
      display: flex;
      flex-direction: row;
      margin: 1rem 0rem;
      img {
        width: px-to-rem(20px);
      }
      .adresse,
      .cp {
        font-size: px-to-rem(17px);
        font-weight: var(--fw-Light);
        text-align: start;
        text-decoration: underline;
        padding-left: px-to-rem(8px);
      }
    }
    .telephone {
      gap: 1rem;
      width: 7rem;
      border:var(--border-neutre) 2px solid;
      font-size: var(--fs-lg);
      font-weight: var(--fw-Regular);
    }
  }
}
.container_Conseiller_Description {
  .description {
    text-align: start;
    white-space: pre-wrap;
    line-height: 1.3rem;
  }
  margin-bottom: 3rem;
}