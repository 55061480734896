.infoNumber {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  padding: 0.1rem;
  outline: white 2px solid;
  color: var(--third);
  font-size: 0.62rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 35px;
}
