@import "../../style/_functionCSS.scss";

.container_Mon_Profil {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  .container_Buttons_Profil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .info_profil {
      margin-top: 5rem;
    }

    .standard {
      width: px-to-rem(213px);
      border-radius: px-to-rem(10px);

      p {
        font-weight: var(--fw-Bold);
        font-size: px-to-rem(22px);
      }
    }
  }

  .small-light {
    width: 10rem;
    height: 2.1rem;
    color: var(--text_Secondaire_Fade);
  }
}
