:root {
  --Container-margin: 2.3rem;
  --Container-width: auto;
}

.Container {
  padding: 0 var(--Container-margin);
  margin: 0 auto;
  width: 100%;
  max-width: var(--Container-width);
  box-sizing: border-box;
}

.Container-sm {
  --Container-width: 15rem;
}

.Container-md {
  --Container-width: 25rem;
}

.Container-lg {
  --Container-width: 40rem;
}
