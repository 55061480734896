@import "./../style/functionCSS";

.container_Modal_Notification {
  display: flex;
  border-radius: 19px;
  width: 95%;
  min-height: 73px;
  max-width: 311px;
  background: rgba(0, 0, 0, 0.29);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  justify-content: space-around;

  .modal-content-Notification {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .text-content-button-Notification {
    display: flex;
    text-align: start;
    gap: 0.3rem;
    flex-direction: column;
    justify-content: center;



    p {
      margin: 0rem;
      color: var(--third);
    }

    .title {
      font-size: var(--fs-sm);
      font-weight: var(--fw-Medium);
    }

    .underTitle {
      font-size: var(--fs-sm);
      font-weight: var(--fw-Light);
      max-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      width: 100%;
      max-width: 9rem;
    }

    .children {
      font-size: px-to-rem(17px);
      font-weight: var(--fw-Light);
      white-space: pre-wrap;
    }
  }
}

.closed-button-Notification {
  width: 20%;
  color: var(--cross);

}

.close {
  display: none;
}