.fw-Thin{
font-weight: var(--fw-Thin);
}
.fw-ExtraLight{
font-weight: var(--fw-ExtraLight);
}
.fw-Light{
    font-weight: var(--fw-Light);
}
.fw-Regular{
    font-weight: var(--fw-Regular);
}
.fw-Medium{
    font-weight: var(--fw-Medium);
}
.fw-SemiBold{
    font-weight: var(--fw-SemiBold);
}
.fw-Bold{
    font-weight: var(--fw-Bold);
}
.fw-ExtraBold{
    font-weight: var(--fw-ExtraBold);
}
.fw-Black{
    font-weight: var(--fw-Black);
}
.fw-ExtraBlack{
    font-weight: var(--fw-ExtraBlack);
}