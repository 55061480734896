.phone-cls-1  {
    fill: #ebbc02;
    stroke: #ebbc02;
    stroke-width: 3px;
  }
.phone-cls-2 {
    fill: #fff;
  }

.phone-cls-3 {
    stroke: none;
  }

.phone-cls-4 {
    fill: none;
  }