.partenaire-cls-1 {
fill: #fff;
stroke: #aec0b7;
stroke-width: 3px;
}
.partenaire-cls-2 {
fill: #aec0b7;
font-size: 36px;
font-family: Heebo-Bold, Heebo;
font-weight: 700;
}
.partenaire-cls-3 {
stroke: none;
}

.partenaire-cls-4 {
fill: none;
}