@import "../../style/mixins/_media-queries.scss";
@import " ./../../../style/base/variables.scss";
.container_chart_DPE_GES {
  display: flex;
  justify-content: center;
  .chart_DPE {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 90%;
    padding: 1rem 0rem 11rem;
    justify-content: center;
    align-items: center;

    .bars {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 0.1rem;
      grid-column: span 7;
      justify-content: space-between;

      .bar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;

        &-letter {
          font-size: 24px;
          font-weight: bold;
          color: white;
        }

        &.epc-A,
        &.epc-B,
        &.epc-C,
        &.epc-D,
        &.epc-E,
        &.epc-F,
        &.epc-G {
          width: 30px;
          border-radius: 10px;
          padding: 0.1rem 0.3rem;
          box-shadow: 0px 3px 6px #00000029;
          &.epc-A {
            background-color: #2ecc71;
          }
          &.epc-B {
            left: calc(20px + var(--input-value) * 10vw);
            background-color: #049164; /* Dark Green */
          }

          &.epc-C {
            left: calc(70px + var(--input-value) * 10vw);
            background-color: #6eb56c; /* Yellow */
          }

          &.epc-D {
            left: calc(120vw + var(--input-value) * 10vw);
            background-color: #f1e216; /* Orange */
          }

          &.epc-E {
            left: calc(170px + var(--input-value) * 10vw);
            background-color: #ecab11; /* Dark Orange */
          }

          &.epc-F {
            left: calc(230px + var(--input-value) * 10vw);
            background-color: #e77831; /* Red Orange */
          }

          &.epc-G {
            left: calc(280px + var(--input-value) * 10vw);
            background-color: #d12220; /* Red */
          }
          &.active {
            transform: scale(1.1);
            margin: 1rem 0.3rem 0rem;
          }
        }
      }

      .epc-bloc {
        width: 60px;
        height: 50px;
        justify-items: center;
        position: relative;

        &.dpe-epc {
          position: absolute;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          left: 0px;
          top: 2.5rem;
          padding: 0.35rem;
          height: 106px;
          border: 1px solid #707070;
          border-radius: 7px;
          opacity: 1;
          transform: scale(0.7);
          .value {
            .infoDPE {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              strong {
                font-size: px-to-rem(17px);
                font-weight: var(--fw-Medium);
              }
              small {
                font-size: px-to-rem(12px);
                display: flex;
                text-align: center;
                width: 100%;
              }
            }
          }
          .separateur {
            width: 100%;
            background-color: #707070;
            height: 0.05rem;
            margin: 0.5rem 0rem;
          }

          @include S-mobile-M {
            top: 4rem;
            transform: scale(0.9);
          }
          @include M-mobile-L {
            top: 4rem;
            transform: scale(1);
          }
          @include L-mobile-Tablet {
            top: 4rem;
            transform: scale(1);
          }
          @include Tablet-and-More {
            top: 4rem;
            transform: scale(1);
          }
        }

        .point {
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid;
          top: -21px;
          left: 50%;
          transform: translateX(-50%);
          background-color: transparent;
        }

        &.epc-A {
          left: -50%;
        }

        &.epc-B {
          left: -52%;
        }

        &.epc-C {
          left: -55%;
        }

        &.epc-D {
          left: -55%;
        }

        &.epc-E {
          left: -55%;
        }

        &.epc-F {
          left: -55%;
        }

        &.epc-G {
          left: -55%;
        }
      }
    }
  }
  .epc-NC {
    position: absolute;
    top: 5rem;
    left: 40%;
    width: 60px;
    height: 50px;
    justify-items: center;
    &.epc-bloc {
      width: 60px;
      height: 50px;
      justify-items: center;
      position: relative;

      &.dpe-epc {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 16dvh;
        top: 5rem;
        padding: 0.35rem;
        height: 106px;
        border: 1px solid #707070;
        border-radius: 7px;
        opacity: 1;
        transform: scale(0.7);
        .value {
          .infoDPE {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            strong {
              font-size: px-to-rem(17px);
              font-weight: var(--fw-Medium);
            }
            small {
              font-size: px-to-rem(12px);
              display: flex;
              text-align: center;
              width: 100%;
            }
          }
        }
        .separateur {
          width: 100%;
          background-color: #707070;
          height: 0.05rem;
          margin: 0.5rem 0rem;
        }

        @include S-mobile-M {
          top: 4rem;
          transform: scale(0.9);
        }
        @include M-mobile-L {
          top: 4rem;
          transform: scale(1);
        }
        @include L-mobile-Tablet {
          top: 4rem;
          transform: scale(1);
        }
        @include Tablet-and-More {
          top: 4rem;
          transform: scale(1);
        }
      }
    }
  }
}
