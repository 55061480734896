@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@800&display=swap");
@import "../../style/mixins/_animations.scss";
.container_Loader {
  height: 100dvh;
  background-color: #ffffff;
  color: Prgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  .logo_Ideeri {
    margin-right: 2rem;
    // @include rotateInfini(360deg, 5s);
  }
}
