@import "../../style/mixins/media-queries";
@import "../../style/base/_variables";
@import "../../style/functionCSS";
.container_Mes_Projet {
  width: 100%;
  .titre {
    h2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0rem;

      .p_Second {
        color: var(--second);
        width: 7.7rem;
        font-size: px-to-rem(64px);
        font-weight: var(--fw-Black);
        margin: 0;
        text-align: center;
      }

      .p_First {
        color: var(--first);
        width: 7.7rem;
        font-size: px-to-rem(39px);
        margin-top: -0.9rem;
        margin-bottom: 0rem;
        font-weight: var(--fw-Black);
        text-align: center;
      }
    }
  }

  
  .screenSize {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
    padding: 5dvh 34.5dvw;
    height: 100%;
    width: 30dvw;
    overflow-y: hidden;
  }

  
}
.container_CreateProject {
  height: 16rem;
}
.container_Buttons_Projets {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.button_projet_card {
  position: relative;
  box-shadow: var(--box-shadow-button);
  cursor: pointer;
  transition: all 250ms ease-in-out;
  position: relative;
  background-color: var(--third);
  border: #c1c1c1 1px solid;
  border-radius: 19px;
  color: var(--text_Principal_Dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  scroll-snap-align: center;
  opacity: 0.5;

  &:hover,
  &.active {
    transform: translateY(-3px) scale(1.1);
    opacity: 1;
  }
  .icon_houseProject{
    position: absolute;
    top: -1.6rem;
    left: 11%;
    width: 6.9rem;
    height: 6.4rem;
  }
  &:hover {
    box-shadow: 0 2px 1rem -7px #000;
    transform: translateY(-3px);
  }
}
.size_card_project{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: px-to-rem(169px);
}