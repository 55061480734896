:root {
    --FormError-textColor: var(--form-invalidColor);
}

.FormError {
    color: var(--FormError-textColor);

    p {
        margin: 0;
    }
}