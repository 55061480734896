.footer-cls-1 {
  fill: #21341c;
}

.footer-cls-2 {
  fill: #fff;
}
.footer-search-cls-1{
  fill: #fff;
}
.footer-search-cls-1,.footer-search-cls-2 {
  stroke: #21341c;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 4px
}