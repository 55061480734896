.FormInput {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);
    --borderRadius: var(--form-borderRadius);
    display: flex;
    width: 100%;
    min-height: var(--form-baseHeight);
    color: var(--textColor);
    background: var(--backgroundColor);
    border-radius: var(--borderRadius);
    padding: var(--form-paddingY) var(--form-paddingX);
    box-sizing: border-box;
    border: var(--form-borderWidth) solid var(--borderColor);
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.FormInput-moreInfo {
    @extend .FormInput;
    text-overflow:initial;
    text-align: center;

}

.FormInput-focus {

    &:focus-visible {
        --borderColor: var(--form-outlineColor);
        outline: var(--form-outlineWidth) solid var(--form-outlineColor);
    }

    &::placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
    }
}

.FormInput-invalid {
    --borderColor: var(--form-invalidColor);
}

.FormInput-invalid {
    --borderColor: var(--form-invalidColor);
}

.FormInput-disabled {
    cursor: default;
    --textColor: var(--form-textColor-disabled);
    --backgroundColor: var(--form-backgroundColor-disabled);
}

.FormInput-outline {
    --borderColor: var(--form-primaryColor);
    --borderRadius: calc(var(--form-baseHeight) / 2);
    --backgroundColor: var(--secondTransparent);;
    --textColor: var(--form-backgroundColor);
}