.FormCounter {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);
    --borderRadius: var(--form-borderRadius);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: var(--form-baseHeight);
    color: var(--textColor);
    background: var(--backgroundColor);
    border-radius: var(--borderRadius);
    padding: var(--form-paddingY) var(--form-paddingX);
    box-sizing: border-box;
    border: var(--form-borderWidth) solid var(--borderColor);

    &:focus-visible {
        --borderColor: var(--form-outlineColor);
        outline: var(--form-outlineWidth) solid var(--form-outlineColor);
    }

    &::placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
    }
}

.FormCounter-icon {
    margin-top: 1em;
}

.FormCounter-invalid {
    --borderColor: var(--form-invalidColor);
}

.FormCounter-disabled {
    cursor: default;
    --textColor: var(--form-textColor-disabled);
    --backgroundColor: var(--form-backgroundColor-disabled);
}

.FormCounter_icon {
    position: absolute;
    left: 50%;
    top: -2px;
    // transform: translate(-50%, -50%);
}

.FormCounter_value {
    flex: 1 1 auto;
    flex-basis: 4em;
    text-align: center;
}

.FormCounter_minus,
.FormCounter_plus {
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    color: var(--form-labelColor);
    width: 24px;
    height: 24px;
    font-size: 20px;
    border-radius: 50%;
    padding: 0;
}