@import "../../../style/functionCSS.scss";
@import "../../../style/base/variables.scss";

.title_card {
  position: absolute;
  font-size: var(--fs-xl);
  font-weight: var(--fw-Bold);
  width: 95%;
}

.subtitle_card {
  font-size: px-to-rem(19px);
  margin: 0.6rem;
  color: var(--text_Secondaire_Fade);
  font-weight: var(--fw-Regular);
  width: inherit;
  height: 2rem;
}

.editing{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0rem;
  color: var(--third);
  border-radius: 0px 0px 19px 19px;
  

  i {
    font-size: px-to-rem(23px);
  }
}
.editing_p {
  color: white;
  font-weight: var(--fw-Regular);
  font-size: px-to-rem(16px);
  margin: 0rem;
  white-space: nowrap;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  align-content: center;
  font-size: 1rem;
}
.editing_p_profil {
  @extend .editing_p;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-xl);
  width: 100%;
  gap: 0.5rem;
}

.editing_Projet {
  position: absolute;
  background-color: var(--first);
  border: 2px solid var(--first);
  height: 2.5rem;
  width: 10.29rem;
  padding: 0rem;
  bottom: -2px;
}

.editing_Profil {
  @extend .editing_Projet;
  background-color: var(--eight);
  border: 2px solid var(--eight);
}

.title_Profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 1.1rem;
}
.editing_Profil{
  background-color: var(--element-backgroundColor-reverse);
  border: 0px solid var(--element-backgroundColor-reverse);
  height: 3rem;
  width: 100%;
}

.noEditing_Profil{
  @extend .editing_Profil;
  background-color: var(--accent);
  border: 0px solid var(--accent);
}

.buttonEditing-noEditingPartenaire {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--accent);
  border-radius: 0px 0px 10px 10px;
  width: inherit;
  height: px-to-rem(50px);
  padding: 0.3rem 0rem;
  color: var(--third);
  border: var(--border-accent) 1px solid;
  border-top: none;
}

.icon_Avatar{
  position: relative;
  width: 12.3rem;
}
.icon_Avatar{
  position: absolute;
  top: -2.5rem;
  width: 6.9rem;
  height: 6.4rem;
}
.avatar_Anonyme{
  left: 25%;
}
.avatar_Profil{
  left: 23%;
}
.firstName,.lastName{
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}
.firstName,.lastName,.addPartenaireName{
  text-align: center;
  margin: 0rem;
}
.firstName,.addPartenaireName{
  font-weight: var(--fw-Light);
}
.lastName{
  font-weight: var(--fw-Bold);
}
.addPartenaireName{
  font-size: 1.5rem;
}
.size_card_profil{
  height: 10rem;
  width: 10.5625rem;
}
.position-card-profil-annonyme{
  position: absolute;
  top:  -7.5rem;
  left: -2rem;
}
.position-card-profil{
  position: absolute;
  top: -12.5rem;
  left: -2.3rem;
}