@import "../../style/functionCSS";
.container_Title {
  margin-bottom: var(--marginBottom);
}

.container_Documents {
  display: flex;
  align-items: center;
  // padding: 12rem 1rem 3rem;
  height: 80dvh;
  width: 100vw;
  overflow-x: hidden;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .buttons_Folders_Files {
    display: grid;
    grid-template-columns: 45% 45%;
    align-content: center;
    justify-items: center;
    justify-content: center;
    align-items: start;
    margin: 7rem 1rem 7rem 1rem;

    button {
      width: 6rem;

      .fa-folder-plus {
        color: var(--accent);
      }
    }
  }
}

.bk_doc {
  background: var(--background-doc);
}
