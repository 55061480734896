@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.container_Icon_Suivi {
  width: 100%;
  max-width: 20rem;
  margin-top: 3rem;
  // scale: 0.7;
  // @include S-mobile-M {
  //   scale: 0.8;
  // }
  // @include M-mobile-L {
  //   scale: 0.9;
  // }
  // @include L-mobile-Tablet {
  //   scale: 1;
  // }
  // @include Tablet-and-More {
  //   scale: 1;
  // }
  a {
    flex: 1 1 0;
    .icon_bien {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 4rem;
      }

      strong {
        color: inherit;
        font-size: 1.75rem;
      }

      p {
        flex: 1 0 0;
        display: flex;
        min-width: 0rem;
        flex-direction: column;
        text-align: center;
        color: transparent;
        font-size: 1rem;
        font-weight: var(--fw-Bold);

        @include M-mobile-L {
          font-size: 1.2rem;
        }

        @include L-mobile-Tablet {
          font-size: 1.2rem;
        }

        @include Tablet-and-More {
          font-size: 1.8rem;
        }
      }
      .green {
        color: var(--second);
      }
    }
    .transparent {
      color: transparent;
    }
  }
}
.text-Data{
  color: var(--text_Secondaire_Fade);
  font-size: var(--fs-sm);
  font-weight: var(--fw-Medium);
  }