@import "../../style/functionCSS";

.container_Header_Title {
  top: 0;
  left: 0;
  height: px-to-rem(115px);
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background: var(--third);
  padding: 1rem 0rem 2rem;
  z-index: 1000;

  h1 {
    font-size: px-to-rem(33px);
    text-align: center;
    font-weight: 900;
    width: 100vw;
    height: px-to-rem(63px);
    // margin: px-to-rem(39px);
  }
}
