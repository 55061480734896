@import "../../style/functionCSS.scss";

.form_estimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17rem;
  margin-top: px-to-rem(52px);
  height: inherit;
  justify-content: center;

  .titre_form_estimation {
    color: var(--second);
    margin: px-to-rem(60px) px-to-rem(0px);
  }

  .container_secondaire_form {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 2rem;
    margin-bottom: 2rem;

    .label_group {
      position: relative;
      display: flex;
      flex-direction: column;
      color: var(--second);
      font-size: px-to-rem(19px);
      font-weight: var(--fw-Regular);

      .standard {
        font-weight: Bold;
        border-radius: 25px;
        width: 12rem;
        height: 2.313rem;
        box-shadow: var(--box-shadow);
      }

      .container_submit {
        width: 70%;
        color: #dfdfdf;
      }
    }
  }
}
