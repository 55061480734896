@import "../../../style/_functionCSS.scss";
@import "../../../style/base/_variables.scss";
@import "../../../style/mixins/media-queries.scss";

.container_Decouverte_4 {
  width: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .legend_titre_form_decouvert {
    margin-bottom: 1rem;
  }

  .form_decouvert {
    position: relative;
    display: flex;
    align-items: center;

    .container_principal_form_decouvert {
      width: 100%;
      min-height: 5rem;
      height: max-content;
      margin: 0rem;

      .entete_form_decouvert {
        font-size: px-to-rem(21px);
        font-weight: var(--fw-Black);
        border-radius: 30px 30px 0px 0px;
        background: var(--accent);
        color: white;
        text-align: center;
        padding: 0.5rem 1rem;
      }

    }

    .edit_Notification {
      display: flex;
      flex-direction: row-reverse;
      align-items: stretch;
      font-size: px-to-rem(16px);
      font-weight: var(--fw-Regular);
      gap: 0.5rem;
      width: inherit;
      color: var(--text_Principal_Dark);
      margin: 2rem;

      #transfert_Decouverte {
        display: flex;
        font-size: px-to-rem(16px);
        font-weight: var(--fw-Regular);
        justify-content: flex-start;
        gap: 0.5rem;
        width: inherit;
        color: var(--text_Principal_Dark);

        @include XS-mobile-S {
          font-size: px-to-rem(16px);
        }
      }
    }

    .container_buttons {
      padding: 2rem 0rem;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 5rem;

      .button_back {
        width: 100%;

        button {
          font-size: px-to-rem(17px);
          font-weight: var(--fw-Bold);
          color: var(--accent);
          display: flex;
          gap: 0.5rem;
        }
      }

      .container_submit {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        margin: 0rem;

        button {
          width: 10rem;

          @include S-mobile-M {
            width: 13rem;
          }

          @include M-mobile-L {
            width: 13rem;
          }

          @include L-mobile-Tablet {
            width: 13rem;
          }

          @include Tablet-and-More {
            width: 13rem;
          }
        }
      }
    }
  }
}
.border_form_decouvert {
  width: 90%;
  border: var(--border-accent) solid 1px;
  border-radius: 35px;
  padding: 1rem;
}

.commentaire_Projets {
  display: flex;
  gap: 1rem;
  outline: 1px solid;
  outline-color: var(--border-accent);
  color: var(--text_Principal_Dark);
  border: 0px;
  flex: 1 1 auto;
  width: 100%;
  min-height: calc(var(--form-baseHeight)* 2);
  border-radius: var(--form-borderRadius);
  padding: 0.5rem;
  box-sizing: border-box;
  line-height: 18.4px;
}

.standard_label{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-weight: var(--fw-Light);
  color: var(--second);
  font-size: px-to-rem(16px);
  margin-right: 0rem;
}