@import "../../../style/_functionCSS.scss";
@import "../../../style/base/variables.scss";
.container_Form_Profil{
  margin: 0rem;
}
.info_Perso {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 90vw;

  p,
  input,
  label {
    padding: px-to-rem(0px);
    font-size: px-to-rem(18px);
    font-weight: var(--fw-Regular);
    color: var(--text_Principal_Dark);
    justify-content: flex-start;
    margin-bottom: 0.25rem;

    .required {
      color: var(--alerte);
      padding: 0rem 0.5rem;
      text-align: center;
      font-size: small;
    }
  }

  input {
    font-size: px-to-rem(15px);
    font-weight: var(--fw-Light);
    height: 1.5rem;
    border: none;
    border-bottom: var(--text_Secondaire_Fade) 1px solid;
    padding: 0rem 0.5rem;
  }
}

.info_Perso {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  min-width: 17rem;
  max-width: 30rem;
  margin: 0rem 1rem;

  .titre_form_info_perso {
    color: var(--second);
    margin-bottom: var(--marginBottom);
  }

  .container_secondaire_form_info_perso {
    width: inherit;

    .legend_form_info_perso {
      justify-content: flex-start;
      font-size: px-to-rem(20px);
      color: var(--accent);
      font-weight: var(--fw-ExtraBold);
      margin-bottom: 1rem;
    }

    .label_group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      .radio_form_info_perso {
        .radios {
          display: flex;
          flex-direction: row;

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          span {
            align-items: center;
            margin: px-to-rem(0px) px-to-rem(15px);

            input {
              appearance: none;
              border-radius: 50%;
              width: 27px;
              height: 27px;

              border: 1px solid #999;
              transition: 0.2s all linear;
              margin-right: 5px;

              position: relative;
              top: 1px;

              &:checked {
                border: 4px solid var(--first);
              }
            }

            label {
              margin: 0rem;
            }
          }
        }
      }

      .required,
      .alerte {
        color: var(--alerte);
        text-align: center;
        font-size: small;
      }

      .alerte {
        margin-top: 0.25rem;
        width: 100%;
      }

      img {
        width: 100%;
      }

      .tel_fixed {
        display: flex;
        flex-direction: column;

        span {
          display: flex;
          align-items: flex-end;
          margin-bottom: 0.5rem;

          label {
            display: flex;
            flex-direction: row;
          }

          input {
            width: 10.25rem;
          }

          button {
            background: none;
            height: 3rem;
            width: 4rem;
            border: none;
          }
        }
      }
    }

    .radio_form_info_perso {
      display: flex;
      flex-direction: column;
    }
  }

  .container_submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: inherit;
    color: var(--first);
    margin-bottom: 5rem;
  }
}

.disabled {
  overflow: hidden;
}
.container_Button_addPhone{
  display: flex;
  justify-content: center;}
