@import "../../style/functionCSS.scss";

.PresentationConseiller {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PresentationConseiller_avatar {
  width: 6rem;
  border-radius: 3rem;
  margin-bottom: px-to-rem(19px);
}

.PresentationConseiller_prenom,
.PresentationConseiller_nom {
  color: var(--second);
  margin: 0rem;
  text-align: center;
  word-break: break-word;
  padding: 0 1em;
  width: 80%;
}

.PresentationConseiller_nom {
  font-size: px-to-rem(32px);
  font-weight: 900;
}

.PresentationConseiller_prenom {
  font-weight: 300;
  font-size: px-to-rem(34px);
}
