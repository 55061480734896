.icon-position-profil {
  position: relative;
  top: 0rem;
  left: 0.3rem;
}
.annonyme-cls-2 {
  fill: url(#pattern);
}
.annonyme-cls-1 {
    fill: #ebbc02;
  }
