.FormSelect {
  --textColor: var(--form-textColor);
  --backgroundColor: var(--form-backgroundColor);
  --borderColor: var(--form-borderColor);
  width: 100%;
  height: auto;
  color: var(--textColor);

  .FormSelect__control {
    width: auto;
    height: auto;
    min-height: var(--form-baseHeight);
    border-radius: var(--form-borderRadius);
    display: flex;
    align-items: center;
    align-content: center;
    background: var(--backgroundColor);
    border: var(--form-borderWidth) solid var(--borderColor);
  }

  .FormSelect__value-container {
    padding: 0 var(--form-paddingX);
  }

  .FormSelect__control--is-focused {
    --borderColor: var(--form-outlineColor);
    box-shadow: 0 0 0 var(--form-outlineWidth) var(--form-outlineColor);
  }

  .FormSelect__placeholder {
    color: var(--form-placeholderColor);
    font-style: italic;
    margin: 0;
  }

  .FormSelect__indicator-separator {
    display: none;
    width: auto;
    height: auto;
  }
}

.FormSelect-invalid {
  --borderColor: var(--form-invalidColor);
}

.FormSelect-disabled {
  cursor: default;
  --textColor: var(--form-disabledColor);
}
.FormSelect__multi-value__label{
  padding: 0px;
  padding-right: 0px;
}
.FormSelect__readonly_P{
  text-overflow: ellipsis;
  overflow: hidden;
  margin: auto;
  text-wrap: nowrap;
  width: 9rem;

}
