.container_Card { 
  .card_Top {
    background-color: var(--third);
    border-radius: 0px;
    .title_Price {
      color: var(--first);
      font-size: 1.62rem;
    }
  }
  .card_Middle {
    img {
      border-radius: 0px;
    }
  }
}
.description_Detail_Immo {
  width: 100%;
  max-width: var(--max-card-size);
  color: var(--text_Principal_Dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  word-break: break-word;

  .container_Icons_Bien {
    width: 100%;
    max-width: var(--max-card-size);
    margin: 0.5rem 0rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 0.5rem;

    .icon_bien {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      fill: var(--second);
      gap: 0.5rem;
      
      img {
        width: 2.5rem;
      }
.container_Litle_Info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  
  small {
    text-align: center;
  }
}

    }
  }
}

.title_Dpe_Conso {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--max-card-size);
  height: 100%;
  padding: 0.5rem;
  font-size: 22px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0px;
  color: #555555;
  background-color: white;
  opacity: 1;
}
// @media screen and (max-width: 340px) {
//   .info_Card {
//     padding: 0rem 5vw;
//     box-shadow: var(--box-shadow-test);
//   }
// }