@import "../../style/functionCSS.scss";

.card_containerNew {
  width: 90%;
  display: flex;
  .tag_new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: px-to-rem(55px);
    height: px-to-rem(33px);
    background-color: var(--second);
    color: var(--text_Principal_Light);
    border-radius: px-to-rem(36px);
    box-shadow: var(--box-shadow-button);
  }
}
