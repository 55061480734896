@import "../../../style/_functionCSS.scss";

.edit_Rayon {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  i {
    display: flex;
    color: var(--second);
    margin: 0.5rem;
    font-size: 1rem;
  }

  p {
    text-align: left;
    color: var(--second);
    font-size: 0.7rem;
    font-weight: 500;
    width: inherit;
  }
}

.commune_Et_Rayon {
  // margin-left: 2.5rem;
  .little_label {
    color: var(--text_Secondaire_Fade);
    font-size: 0.7rem;
    font-weight: 500;
    width: inherit;
  }

  .css-t3ipsp-control {
    width: 100%;
    border-radius: 3rem;
    border: var(--first) 2px solid;
    box-shadow: var(--first) 2px solid;

    &:hover {
      border: var(--first) 2px solid;
      box-shadow: var(--first) 2px solid;
    }
  }

  .communes_Proximity {
    .css-b62m3t-container {
      // max-width: 80%;
    }

    .css-wsp0cs-MultiValueGeneric {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      border-radius: 2px;
      color: hsl(0, 0%, 20%);
      font-size: 85%;
      padding: 3px;
      padding-left: 6px;
      box-sizing: border-box;
    }
  }
}

.title_desired {
  color: var(--second);
  font-weight: 500;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
  }

  .container_desired {
    display: flex;
    align-items: center;
    justify-content: center;

    .desired {
      display: flex;
      flex-direction: column;
      align-items: center;

      .standard_label {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-weight: var(--fw-Light);
        color: var(--second);
        font-size: px-to-rem(16px);
        margin-right: 0rem;
      }

      .desired_surface {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .input_m2,
      .input_euros {
        display: flex;
        padding: 0rem 0.4rem;
        height: 1.7rem;
        width: 5rem;
        border-radius: 6px;
        align-items: center;
        background-color: var(--bc_Card);
        margin-bottom: 0.5rem;

        input {
          border: none;
          border-radius: 0px;
          width: 100%;
          font-size: px-to-rem(17px);
          font-weight: var(--fw-Medium);
          background-color: var(--bc_Card);

          &:focus-visible {
            outline: -webkit-focus-ring-color none;
          }
        }

        .m2,
        .euros {
          font-size: px-to-rem(14px);
          font-weight: var(--fw-Light);
          color: var(--text_Secondaire_Fade);
        }
      }

      .container_budgets {
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;

        .container_budgetMin,
        .container_budgetMax {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .container_budget_info_plus {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        textarea {
          background-color: var(--bc_Card);
          border: none;
          width: 95%;
        }
      }
    }
  }

  .container_chambre {
    display: flex;
    gap: 15%;
    position: relative;
    margin: 1.5rem 0rem;
    border: 2px solid var(--text_Secondaire_Fade);
    border-radius: 8px;
    height: 2.2rem;
    width: 60%;
    align-items: center;

    .p_number {
      font-size: px-to-rem(17px);
      font-weight: var(--fw-Medium);
      text-align: center;
      margin: 0rem;
    }

    img {
      position: absolute;
      bottom: 115%;
      width: 30%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.7rem;
    height: 1.7rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    color: var(--text_Secondaire_Fade);
    font-size: 1.14286rem;
  }
}

.container_buttons {
  padding: 2rem 0rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  .button_back {
    width: 100%;

    button {
      font-size: px-to-rem(17px);
      font-weight: var(--fw-Bold);
      color: var(--accent);
      display: flex;
      gap: 0.5rem;
    }
  }

  .container_submit {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin: 0rem;

    button {
      width: 7rem;
    }
  }
}
