@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.HeaderBack {
  position: relative;
  text-align: left;
  background: white;
  padding: 0.5rem 0rem 0.25rem 2rem;
  box-shadow: -1px -11px 20px 0px rgb(0 0 0 / 40%);

  a {
    background: none;
    border: none;
    color: #aec0b7;
    text-decoration: none;
    p {
      padding: 0.5rem;
    }
  }
  &_transparent {
    position: relative;
    padding: 0.5rem 0rem 0.25rem 2rem;
    margin: -3rem 0rem 1rem;
    z-index: 1000;
    a {
      background: none;
      border: none;
      color: #aec0b7;
      text-decoration: none;
      p {
        padding: 0.5rem;
      }
    }
  }
}
