.word-list {
  display: flex;
  flex-direction: row;
  width: 15rem;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  .word-available {
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--background-doc);
    color: var(--text_Principal_Dark);
    font-size: var(--fs-md);
    font-weight: var(--fw-Medium);
    
    &:hover {
      background-color: var(--first);
      font-size: var(--fs-md);
      font-weight: var(--fw-Medium);
      color: var(--text_Principal_Dark);
    }
    
  }
}
.add_More {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  input {
    width: 7rem;
    padding-left: 1.5rem;
  }
  i{
    border-radius: 50%;
    
  }
}
.adding_Word {
background: #DFDFDF 0% 0%;
padding: 0.3rem;
border:0px;
border-radius: 8px;
}
.selected-words {
  border: 1px solid;
  border-radius: 16px;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  width: inherit;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding: 0.5rem;
  .word-selected {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: var(--fs-md);
    font-weight: var(--fw-Medium);
  }
  .colorSouhait{
    background-color:var(--accent);
  }
  .colorRedhibitoires{
    background-color: var(--first);
  }
}

.border-colorSouhait{
  border-color: var(--border-accent);
  box-shadow: var(--box-shadow-big);
}
.border-colorSouhait{
  border-color: var(--border-accent);
  box-shadow: var(--box-shadow-big);
}
.border-colorRedhibitoires{
  border-color: var(--first);
  box-shadow: var(--box-shadow-big);
}