:root {
  --Title-title-fontSize: inherit;
  --Title-title-textColor: inherit;
}

.Title-center {
  display: flex;
  justify-content: center;
}

.Title-start {
  display: flex;
  justify-content: flex-start ;
}
.Title-end {
  display: flex;
  justify-content: flex-end ;
}
.Title_content {
  display: flex;
  flex-direction: column-reverse;
}

.Title_title {
  color: var(--Title-title-textColor);
  font-size: var(--Title-title-fontSize);
  font-weight: var(--fw-ExtraBold);
  margin: 1rem 0 1rem;
}

.Title_subtitle {
  font-weight: var(--fw-Light);
  font-size: var(--fs-md);
  color: var(--text_Secondaire_Fade);
  // width: 100%;
  min-width: 14rem;
  width: 100%;
  max-width: 20rem;
  margin: 1em 0 0;
}

.Title-1 {
  --Title-title-fontSize: var(--fs-3xl);
}

.Title-2 {
  --Title-title-fontSize: var(--fs-xxl);
}

.Title-3 {
  --Title-title-fontSize: var(--fs-xl);
}
.Title-4 {
  --Title-title-fontSize: var(--fs-lg);
}

.Title-first {
  --Title-title-textColor: var(--first);
}

.Title-second {
  --Title-title-textColor: var(--second);
}
