@import "../../../style/functionCSS";
@import "../../../style/mixins/mixin";
@import "../../../style/mixins/_media-queries.scss";
:root{
  --max-card-size:40rem
}

.container_Card {
  position: relative;
  width: 100%;
  max-width: var(--max-card-size);
  margin-bottom: calc(var(--marginBottom) * 2);
  z-index: 0;
  .card_Images {
    position: relative;
    width: 100%;
    max-width: var(--max-card-size);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: stretch;
    img {
      object-fit: cover;
      max-height: 35dvh;
    }
    @include S-mobile-M {
      img {
        max-height: 35dvh;
      }
    }
    @include M-mobile-L {
      img {
        max-height: 38dvh;
      }
    }

    @include L-mobile-Tablet {
      img {
        max-height: 38dvh;
      }
    }
    @include Tablet-laptop {
      img {
        max-height: 40dvh;
      }
    }
    @include M-laptop-L {
      img {
        max-height: 40dvh;
      }
    }
    @include L-laptop-XL {
      img {
        max-height: 40dvh;
      }
    }
    @include XL-laptop {
      img {
        max-height: 40dvh;
      }
    }

    .pictogrammes {
      padding: 1rem;
      position: absolute;
      z-index: 1;
      display: flex;
      // gap: 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-height: 29dvh;
      @include backgroundBlur;
      @include S-mobile-M {
        max-height: 26dvh;
      }
      @include M-mobile-L {
        max-height: 26dvh;
      }

      @include L-mobile-Tablet {
        max-height: 26dvh;
      }
      @include Tablet-laptop {
        max-height: 26dvh;
      }
      @include M-laptop-L {
        max-height: 26dvh;
      }
      @include L-laptop-XL {
        max-height: 26dvh;
      }
      @include XL-laptop {
        max-height: 32dvh;
      }

      .picto {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2rem;
        flex: 0.1 0 auto;
        img {
          object-fit: cover;
        }

        small {
          text-align: center;
          font-size: px-to-rem(10px);
          color: var(--text_Principal_Dark);
          width: 100%;
          font-weight: var(--fw-Medium);
        }
      }
    }
  }

  .card_Bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: var(--max-card-size);
    height: inherit;
    margin-bottom: var(--marginBottom);

    .container_Info_Card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: inherit;
      height: inherit;
      margin: 0.3rem 1rem;
      button {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

      .container_Price_Action {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 3rem;

        .icons_Action {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          gap: 0.6rem;
        }
      }

      .title,
      .title_Price,
      .title_Card,
      .title_Ville,
      .title_Cp {
        color: var(--text_Principal_Dark);
      }

      .title {
        font-size: px-to-rem(21px);
        font-weight: var(--fw-light);
      }
      .title_Price {
        font-size: px-to-rem(26px);
        font-weight: var(--fw-Black);
      }
      .infoMontant{
        font-size: 60%;
        font-weight: var(--fw-Regular);
      }

      .title_Card {
        font-size: px-to-rem(21px);
        font-weight: var(--fw-Light);
        margin: 0rem;
        margin-bottom: 0.5rem;
      }

      .container_Title_Coordonee {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .container_Location {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
          gap: 0.5rem;
          .title_Ville {
            font-size: px-to-rem(16px);
            font-weight: var(--fw-Bold);
          }

          .title_Cp {
            margin-top: 0.2rem;
            font-size: px-to-rem(14px);
            font-weight: var(--fw-Light);
          }
        }
      }
    }
  }
}
.background_Modal {
  .container_Modal_Glow {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 78%;
    width: 100%;
    transform: translate(0%, 0%);
    z-index: 90000000000000000000000000;
    background: rgba(0, 0, 0, 0.21);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
    .modal-content {
      max-width: initial;
      width: 95%;
      height: 100%;
      Form {
        padding: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
