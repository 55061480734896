.locality-cls-1 {
    fill: #21341c;
  }

.locality-cls-2 {
fill: none;
stroke: #fff;
stroke-linecap: round;
stroke-linejoin: round;
stroke-width: 2px;
}