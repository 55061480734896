@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.container_Home_Modal {
  position: relative;
  top: -9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .container_retour {
    top: 0.3rem;
    a {
      color: var(--third);
    }
    .container_TitleGlow {
      button {
        color: var(--first);
      }
    }
  }
}
._homeAchatVenteBienBack {
  padding: 0.6rem 0rem 0.25rem 2rem;
  margin: -7.5rem 0rem 3rem;
  a {
    color: white;
  }
  .-link-neutre {
        color: var(--logo_Icon_Light);
      }
}
.icon_house_HomeAchat{
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.container_Home_Achat {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - var(--Footer-height));
  margin-top: 1rem;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  .container_ButtonsDragonfly {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100dvw;
    max-width: 32rem;
    scale: 0.8;
    @include S-mobile-M {
      scale: 0.9;
    }
    @include M-mobile-L {
      scale: 0.9;
    }
    @include L-mobile-Tablet {
      scale: 0.9;
    }
    @include Tablet-and-More {
      scale: 0.9;
    }
  }

  .medium {
    position: relative;
    height: initial;
    width: initial;
    font-size: px-to-rem(30px);
    font-weight: var(--fw-Black);
    padding: 1.5rem;
    margin-top: 0.5rem;
    height: 100%;
    min-height: px-to-rem(24px);
    max-height: px-to-rem(35px);
    scale: 0.8;
    @include M-mobile-L {
      scale: 1;
    }
    @include L-mobile-Tablet {
      scale: 0.9;
    }
    @include Tablet-and-More {
      scale: 0.9;
    }
    img {
      position: absolute;
      top: -1.7rem;
    }
  }

  .small-light {
    color: var(--text_Secondaire_Fade);
  }

  .raisonCloture {
    display: flex;
    flex-direction: column;
    align-items: center;
    .FormControl {
      margin: 1rem;
    }
  }
}
