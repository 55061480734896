@import "../../../style/functionCSS.scss";
@import "../../../style/mixins/media-queries.scss";
@import "../../../style/base/_variables.scss";

.buttonDragonfly {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  // gap: 0.5em;
  margin: 0.5rem 0.3rem;
  font-size: px-to-rem(20px);
  width: px-to-rem(115px);
  min-width: px-to-rem(125px);
  width: 100%;
  max-width: px-to-rem(130px);
  position: relative;
  background-color: var(--third);
  border: var(--border-accent) 2px solid;
  color: var(--text_Principal_Dark);
  justify-content: center;
  text-align: center;
  font-weight: var(--fw-Bold);
  padding: 0 1em;
  height: px-to-rem(48px);
  box-shadow: var(--box-shadow-button);

  &.topLeftRadius {
    border-top-left-radius: 25px;
  }

  &.topRightRadius {
    border-top-right-radius: 25px;
  }

  &.bottomLeftRadius {
    border-bottom-left-radius: 25px;
  }

  &.bottomRightRadius {
    border-bottom-right-radius: 25px;
  }

  .infoNumber {
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;
  }
}
.buttonDragonflyDisabled{
  color: var(--text-Principal_Dark-Disabled);
  border: var(--border-disabled) 2px solid;
}
