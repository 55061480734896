@import "../../style/functionCSS";

.container_Carroussel {
  display: flex;
  width: 100vw;
  display: flex;
  // max-width: px-to-rem(390px);
  max-width: 40rem;

  .mySwiper {
    object-fit: cover;
    width: 100%;

    .swiper-wrapper {
      padding-inline-start: 0;

      .swiper-slide-active {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 35dvh;
        object-fit: cover;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background: var(--third);
        opacity: 0.6;
      }
      .swiper-pagination-bullet-active {
        background: var(--third);
        outline: var(--first) 3px solid;
        opacity: 1;
      }
    }
  }
}
