.section_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
}
.legend_titre_form_decouvert {
  margin-bottom: -1rem;
  font-weight: 300;
  font-size: 1rem;
  color: #c1c1c1;
  width: 100%;
  text-align: start;
}
.titre_form_decouvert {
  color: var(--second);
  top: 138px;
  left: 47px;
  width: inherit;
  height: 37px;
  font-size: 1.3rem;
  font-weight: 900;
  margin-bottom: 2rem;
}
.button_back {
  a {
    background: none;
    border: none;
    color: #aec0b7;
  }
}
