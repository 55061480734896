@import "../../../style/functionCSS.scss";
@import "../../../style/mixins/_media-queries";

.container_Card_Detail_Immo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--max-card-size);
  margin-top: var(--marginBottom);
  z-index: 0;

  .card_Top_Detail_Immo {
    position: relative;
    display: flex;
  width: 100%;
  max-width: var(--max-card-size);
    box-sizing: border-box;
    flex: 1;
    flex-direction: column;
    padding: 0.5rem;

    h2,
    div {
      margin: 0.25rem 0rem;
      margin-bottom: 0.2rem;
    }

    .title_Coordonee_Card_Detail_Immo {
      display: block;
      flex: 2 1;
      text-align: end;

      i {
        font-size: 1.3rem;
        padding: 0rem 0.3rem;
      }
    }

    .title_Card_Detail_Immo {
      color: var(--text_Principal_Dark);
      font-size: px-to-rem(26px);
      font-weight: var(--fw-Light);
    }

    @media screen and (max-width: 320px) {
      .title_Card_Detail_Immo {
        font-size: px-to-rem(22px);
      }
    }

    .container_Price_Coordonee {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0.5rem 0rem;
      gap: 1rem;
      width: 95%;
      justify-content: space-between;
      .title_Price_Card_Detail_Immo {
        font-weight: var(--fw-Black);
        color: var(--first);
        font-size: px-to-rem(24px);
      }

      .title_Coordonee_Card_Detail_Immo {
        img {
          height: 1rem;
          width: 1rem;
          padding: 0rem 0.3rem;
        }
        .title_Ville_Card_Detail_Immo,
        .title_Cp_Card_Detail_Immo {
          color: var(--text_Principal_Dark);
          font-size: px-to-rem(14px);
        }

        .title_Ville_Card_Detail_Immo {
          font-size: px-to-rem(16px);
          font-weight: var(--fw-Bold);
        }
        .title_Cp_Card_Detail_Immo {
          display: block;
          font-size: px-to-rem(14px);
          font-weight: 300;
        }
      }

      @include L-mobile-Tablet {
        .title_Price_Card_Detail_Immo {
          font-size: px-to-rem(25px);
        }

        .title_Coordonee_Card_Detail_Immo {
          img {
            height: 1rem;
            width: 1rem;
            padding: 0rem 0.3rem;
          }

          .title_Ville_Card_Detail_Immo {
            font-size: px-to-rem(16px);
          }
        }
        .container_Cp_Ref_Immo {
          .title_Cp_Card_Detail_Immo {
            font-size: px-to-rem(14px);
          }
        }
      }
      @include Tablet-and-More {
        .title_Price_Card_Detail_Immo {
          font-size: px-to-rem(29px);
        }

        .title_Coordonee_Card_Detail_Immo {
          img {
            height: 1rem;
            width: 1rem;
            padding: 0rem 0.3rem;
          }

          .title_Ville_Card_Detail_Immo {
            font-size: px-to-rem(22px);
          }
        }
        .container_Cp_Ref_Immo {
          .title_Cp_Card_Detail_Immo {
            font-size: px-to-rem(22px);
          }
        }
      }
    }
  }

  .card_Middle_Card_Detail_Immo {
    .container_Carroussel {
      width: 100dvw;
      max-width: var(--max-card-size);
      height: 100%;
      max-height: 32dvh;
    }
    img {
      object-fit: cover;
      max-height: 32dvh;
    }
    @include S-mobile-M {
      img {
        max-height: 32dvh;
      }
    }
    @include M-mobile-L {
      img {
        max-height: 38dvh;
      }
    }

    @include L-mobile-Tablet {
      img {
        max-height: 38dvh;
      }
    }
    @include Tablet-laptop {
      img {
        max-height: 40dvh;
      }
    }
    @include M-laptop-L {
      img {
        max-height: 40dvh;
      }
    }
    @include L-laptop-XL {
      img {
        max-height: 40dvh;
      }
    }
    @include XL-laptop {
      img {
        max-height: 40dvh;
      }
    }
  }
  .card_Bottom_Card_Detail_Immo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    max-width: var(--max-card-size);
    margin: 1rem;
    height: 3rem;
    margin-bottom: 1.063rem;
  }
}
