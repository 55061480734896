.img-hxs{
    height: var(--img-xs);
}
.img-hmd{
    height: var(--img-md);
}
.img-hlg{
    height: var(--img-lg);
}
.img-hxl{
    height: var(--img-xl);
}
.img-hxxl{
    height: var(--img-xxl);
}
.img-h3xl{
    height: var(--img-3xl);
}
.img-h4xl{
    height: var(--img-4xl);
}
.img-h5xl{
    height: var(--img-5xl);
}
.img-wxs{
    width: var(--img-xs);
}
.img-wmd{
    width: var(--img-md);
}
.img-wlg{
    width: var(--img-lg);
}
.img-wxl{
    width: var(--img-xl);
}
.img-wxxl{
    width: var(--img-xxl);
}
.img-w3xl{
    width: var(--img-3xl);
}
.img-w4xl{
    width: var(--img-4xl);
}
.img-w5xl{
    width: var(--img-5xl);
}
.size-card-Project{
    width: 6.9rem;
    height: 6.4rem;
}
.position-card-Project{
    position: absolute;
    top: -8.3rem;
}
.position-card-Bien{
    position: absolute;
    top: -2.5rem;
}
.size-img-card-profil{
    width: 77px;
    height: 77px;
}
