.container_Modal_Titre {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 300px;
    overflow: hidden;
    border: #aec0b7 1px solid;
    background-color: white;
    border-radius: 35px 35px 35px 35px;
    box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 16%);
    .title {
      background: var(--accent);
      width: inherit;
      text-align: center;
      font-size: var(--title);
      font-weight: bold;
      color: var(--third);
      margin: 0rem;
      padding: 0.5rem 0rem;
    }
    .bloc_Button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1rem 1rem 0.5rem 1rem;
      background: none;
      border: none;
      p {
        color: #535353;
        font-size: medium;
        font-weight: bold;
      }
      img {
        margin-right: 1rem;
        width: 2.8rem;
        height: 1.8rem;
      }
    }
  }
}
