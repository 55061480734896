:root {
  --FormChip-textColor: var(--form-textColor);
  --FormChip-placeholderColor: var(--form-placeholderColor);
  --FormChip-backgroundColor: var(--form-backgroundColor);
  --FormChip-backgroundColor-checked: var(--form-primaryColor);
  --FormChip-borderColor: var(--form-borderColor);
  --FormChip-outlineColor: var(--form-outlineColor);
  --FormChip-invalidColor: var(--form-invalidColor);
  --FormChip-disabledColor: var(--form-disabledColor);
}

.FormChip {
  --textColor: var(--FormChip-textColor);
  --backgroundColor: var(--FormChip-backgroundColor);
  --outlineColor: var(--FormChip-outlineColor);
  --borderColor: var(--FormChip-borderColor);
  --height: var(--form-baseHeight);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  min-height: var(--height);
  border-radius: calc(var(--height) / 2);
  color: var(--textColor);
  background: var(--backgroundColor);
  box-sizing: border-box;
  padding: var(--form-paddingY) calc(var(--form-paddingY) * 2);
  margin: 0;
  border: 1px solid var(--borderColor);
  position: relative;

  &:focus-visible {
    --borderColor: var(--form-outlineColor);
    outline: var(--form-outlineWidth) solid var(--outlineColor);
  }
}

.FormChip-invalid {
  --borderColor: var(--FormChip-invalidColor);
}

.FormChip-disabled {
  cursor: default;
  --textColor: var(--FormChip-disabledColor);
}

.FormChip-checked {
  --backgroundColor: var(--FormChip-backgroundColor-checked);
  --borderColor: var(--FormChip-backgroundColor-checked);
}