@import "../../style/functionCSS.scss";
@import "../../style/mixins/mixin";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";
.container_Modal_Title_Glow {
  position: absolute;
  top: 4rem;
  min-width: 217px;
  width: 250px;
  height: 60px;

  .modal-content {
    @include backgroundBlur(rgba(249, 249, 249, 0.5294117647));
    border-radius: 18px;
    border: 1px solid #00000029;
    display: flex;
    flex-direction: row;
    padding: px-to-rem(7px);
    img {
      padding-left: 1rem;
      width: 3.5rem;
    }
    // ##V1
    .text-content-button {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 65%;
      width: inherit;
      align-self: start;
      align-items: flex-start;
      p {
        color: var(--second);
        text-align: start;
        padding-left: 0.5rem;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
      }
      .title {
        font-size: px-to-rem(19px);
        font-weight: var(--fw-Bold);
        &.Conseiller {
          align-items: stretch;
        }
      }
      .children {
        
        white-space: nowrap;
        width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: px-to-rem(16px);
        font-weight: var(--fw-Light);
      }
    }
    
    // ##V2
    // .text-content-button {
    //   position: relative;
    //   width: 65%;
    //   p {
    //     color: var(--second);
    //     display: flex;
    //     justify-content: center;
    //     align-self: start;
    //     align-items: flex-start;
    //     flex-direction: column;
    //     text-align: start;
    //     padding-left: 0.5rem;
    //     margin-top: 0.1rem;
    //     margin-bottom: 0.1rem;
    //   }
    //   .title {
    //     font-size: px-to-rem(19px);
    //     font-weight: var(--fw-Bold);
    //     &.Conseiller {
    //       align-items: stretch;
    //     }
    //   }
    //   .children {
    //     font-size: px-to-rem(16px);
    //     font-weight: var(--fw-Light);
    //     text-wrap: pretty;
    //   }
    // }
    .titleGlow_edit {
      position: absolute;
      color: var(--second);
      top: 0.5rem;
      right: 0.4rem;
    }
  }
  &.Conseiller {
    position: initial;
    margin-bottom: var(--marginBottom);
    img {
      position: fixed;
      top: 15%;
      left: 5%;
      width: 39px;
      height: 39px;
      border-radius: 13px;
      padding-left: 0rem;
    }
  }
}
