.AuthLayout {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    justify-content: flex-end;
    background-image: url("../../img/bcGreen.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;

    @media (min-width: 680px) {
        background-size: cover;
        background-position: center top;
    }
}

.AuthLayout_title {
    padding-top: 6rem;
}

.AuthLayout_content {
    margin-block-start: auto;
    color: var(--element-textColor-reverse);
}

.AuthLayout_background {
    display: none;
    z-index: -1;
    left: 0rem;
    bottom: 0rem;
    height: 75dvh;
    width: 100vw;
    position: fixed;
}