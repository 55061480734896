.FormTextarea {
    --textColor: var(--form-textColor);
    --backgroundColor: var(--form-backgroundColor);
    --borderColor: var(--form-borderColor);

    display: flex;
    width: 100%;
    min-height: calc(var(--form-baseHeight) * 2);
    color: var(--textColor);
    background: var(--backgroundColor);
    border-radius: var(--form-borderRadius);
    padding: var(--form-paddingX);
    box-sizing: border-box;
    border: var(--form-borderWidth) solid var(--borderColor);

    &:focus-visible {
        --borderColor: var(--form-outlineColor);
        outline: var(--form-outlineWidth) solid var(--form-outlineColor);
    }

    &::placeholder {
        color: var(--form-placeholderColor);
        font-style: italic;
    }
}

.FormTextarea-invalid {
    --borderColor: var(--form-invalidColor);
}

.FormTextarea-disabled {
    cursor: default;
    --textColor: var(--form-textColor-disabled);
    --backgroundColor: var(--form-backgroundColor-disabled);
}