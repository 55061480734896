@import "../../../style/functionCSS";

.container_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 2rem;
  width: 100%;
  margin-bottom: 0.6rem;
  background: #f9f9f987;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
  padding: 1rem 0rem;

  .two_Col {
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;

    .trash {
      background: none;
      border: none;

      i {
        padding: 2px;
        margin: 0px 5px;
        font-size: px-to-rem(27px);
        font-weight: bold;
        color: var#1C2C18;
      }
    }
  }

  button {
    margin: 0.5rem 5vw;
  }

  .options_add {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: px-to-rem(144px);
    height: px-to-rem(37px);
    background-color: var(--first);
    border-radius: px-to-rem(25px);
    border: var(--first) px-to-rem(1px) solid;
    color: white;
    font-weight: 700;
    gap: 0.5rem;

    .icon_button_plus {
      color: #535353;
      width: 30px;
      height: 30px;
      font-size: 26px;
      font-weight: 700;
      background-color: white;
      border: #535353 1px solid;
      border-radius: 50%;
      margin: 0rem;
    }
  }
}