.IconRendezVousConfirmation {
    position: relative;
    display: inline-block;
}

.IconRendezVousConfirmation_stars {
    position: absolute;
    left: 100%;
    bottom: calc(100% - 23px);
}

.IconRendezVousConfirmation_check {
    position: absolute;
    top: calc(100% - 23px);
    right: calc(100% - 17px);
}