.container_More_Communes {
  .label_nb_Communes {
    text-align: center;
    width: 100%;
  }

  .container_Communes_Proximity {
    margin: 1rem 0rem;
    align-content: flex-start;

    .communes_Proximity {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 100ms;
      height: 100%;
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      padding: 0.1rem;

      .css-b62m3t-container {
        margin: 0rem;
        padding: 0.1rem;

        .css-13cymwt-control {
          border: none;
          width: 100%;
          margin: 0.5rem 0rem;
        }

        .css-t3ipsp-control {
          width: 100%;
          border-radius: 3rem;
          border: none;
          box-shadow: none;

          &:hover {
            border: none;
            box-shadow: none;
          }
        }

        .css-3w2yfm-ValueContainer {
          // display: flex;
          // align-items: flex-start;
          // align-content: flex-start;
          // border: 2px solid;
          padding: 6px 20px;
          width: 10rem;
          padding: 0rem;
        }
      }

      button {
        height: 2rem;
        margin: 0rem 0rem 1rem 0rem;

        p {
          font-size: 0.6rem;
        }
      }
    }
  }
}