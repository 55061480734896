@import "../../style/functionCSS.scss";
@import "../../style/base/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: var(--fw-Bold);
  color: var(--text_Principal_Light);
  border: none;
  padding: 0.1rem 1rem;
  border-radius: 25px;
  height: px-to-rem(37px);
  box-shadow: var(--box-shadow-button);
  box-sizing: content-box;
  gap: 0.5rem;
}

.Button-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.button-simple {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.button {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem;
  }
}

.small {
  font-size: px-to-rem(18px);
  background-color: transparent;
  box-shadow: none;

  p {
    font-size: px-to-rem(15px);
  }
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.small-light {
  @extend .small;

  small {
    @extend .light;
    @extend .underline;
  }
}

.small-bold {
  @extend .small;

  small {
    @extend .bold;
  }
}

.standard {
  position: relative;
  background-color: var(--third);
  border: var(--border-accent) 2px solid;
  border-radius: 35px;
  color: var(--text_Principal_Dark);
  height: px-to-rem(43px);
  padding: 0.1em 1em;
}

.not_Valid {
  color: var(--not-valid);
}

.projet {
  display: flex;
  flex-direction: column;
  border: var(--second) 1px solid;
  color: var(--text_Principal_Dark);
  box-shadow: var(--box-shadow);
  img {
    position: absolute;
    top: -23.5%;
    width: 2.8rem;
    height: 3rem;
    margin: 0rem;
    padding: 0rem;
  }
  .icon_house {
    position: absolute;
    top: -3.5rem;
    left: -1.7rem;
  }

  //page 'CreateProject'/'CreateProjectPage','home'
  .container_icon_plus_ideeri {
    position: absolute;
    padding: 0rem;
    left: 28%;
    top: -14%;
  }
  //page 'Home_Achat/Home_Vente'
  .container_icon_plus_ideeri_Pro {
    position: absolute;
    padding: 0rem;
    left: 35%;
    top: -18%;
  }
}
.back {
  background: none;
  border: none;
  color: #aec0b7;
  text-decoration: none;
  decorat p {
    padding: 0.5rem;
  }
}

.medium {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--second);
  border-radius: px-to-rem(43px);
  min-height: px-to-rem(100px);
  min-width: px-to-rem(130px);
  width: px-to-rem(128px);

  p {
    font-size: 1.58rem;
    font-weight: 900;
  }
}

.big {
  border-radius: 45px;
  padding: 0rem 1rem;
  width: min-content;
  min-height: px-to-rem(110px);
  min-width: px-to-rem(100px);

  p {
    color: var(--second);
    font-weight: var(--fw-Black);
  }
}

.container_folder_file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  button {
    width: inherit;
  }

  .folder_file_card {
    background: none;
    color: var(--third);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;

    i {
      font-size: 4rem;
    }

    textarea {
      text-align: center;
      font-weight: 700;
      font-size: 1rem;
      background: none;
      border: none;
      color: white;
      padding: 0.5rem 0rem;
    }
  }

  button {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    border-radius: 15px;
    border: none;
  }
}

.first {
  border-radius: 25px 25px 0px 0px;
}

.middle {
  border-radius: 0px 0px 0px 0px;
}

.end {
  border-radius: 0px 0px 25px 25px;
}

.parametre {
  border: none;
  background: none;

  img {
    width: 1.875rem;
  }
}

.yellow {
  background-color: var(--first);
  box-shadow: var(--box-shadow);
}

.gray {
  background-color: #37353566;
  color: var(--text_Principal_Light);
  box-shadow: var(--box-shadow);
}

.-link-neutre {
  color: var(--logo_Icon_Dark);
  padding: 0rem;
  margin: 0rem;
}

.container_member {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid var(--border-accent);
  border-radius: 1.5rem;
  width: 8rem;
  height: 6rem;
  color: var(--second);

  .p_member {
    font-size: 1rem;
    font-weight: bold;
  }

  .p_number {
    font-size: 2rem;
    font-weight: bold;
  }

  .container_incrementing {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    button {
      margin: 0rem;
      padding: 0rem;
    }

    i {
      color: var(--accent);
      border-radius: 50%;
      border: 3px solid var(--border-accent);
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      font-weight: bolder;
      padding: 0.2rem;
    }
  }
}
.containerImage{
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

//use in form to add an image
input[type=file] {
  display: none;
}
.formFile{
display: flex;
align-items: center;
justify-content: flex-start;
gap: 1rem;
}