@import "../../../../style/functionCSS.scss";

.container_Modal_InfoMedium {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(104, 99, 99, 0.69);
  height: 100%;
  width: 100%;
  z-index: 20000;
  /* display: none; */
  align-items: center;
  justify-content: center;
  
  .modal_InfoMedium {
    position: relative;
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 14.5rem;
    width: 21rem;
    border: #aec0b7 1px solid;
    background-color: white;
    border-radius: 35px;
    box-shadow: 0px 3px 6px 1px #00000029;
  }
  .titleInfoMedium {
    color: var(--first);
    font-weight: var(--fw-Black);
    font-size: var(--fs-lg);
  }
  .textInfoMedium {
    text-align: center;
    color: var(--text_Principal_Dark);
    font-weight: var(--fw-Regular);
    font-size: var(--fs-lg);
  }
  .iconMoreInfo{
    position: absolute;
    transform: translate(50%, 50%);
    top: 25%;
    right: 1%;
    fill: var(--first);
    scale: 0.5;
  }
  .ColorInfoMedium{
    fill: var(--first);
  }

}
