@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.button_home {
  margin: 0rem 0.5rem;
}

.container_Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  justify-content: center;

  .container_PresentationUser {
    display: flex;
    flex-direction: column;
    width: inherit;
    align-items: center;
    justify-content: center;
  }

  .container_ButtonsDragonfly {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 32rem;
    scale: 0.8;
    @include S-mobile-M {
      scale: 0.9;
    }
    @include M-mobile-L {
      scale: 1;
    }
    @include L-mobile-Tablet {
      scale: 0.9;
    }
    @include Tablet-and-More {
      scale: 0.9;
    }
  }
  .big {
    padding-top: 0.5rem;
  }

  .button_Parrainage {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    display: none;

    .parrainage {
      position: fixed;
      bottom: 3.1rem;
      width: 8rem;
      height: 9rem;
      border-radius: 130px 0px 0px;
      margin: 0rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 2rem 0rem 1rem 1.25rem;

      img {
        border: var(--third) 2px solid;
        border-radius: 35px;
        width: 2rem;
        height: 2rem;
      }

      p {
        width: 6rem;
        font-weight: lighter;
      }
    }
  }
}
