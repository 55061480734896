@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../style/functionCSS";
@import "../style/base/normalize.css";
@import "../style/base/variables";
@import "../style/mixins/animations.scss";
@import "../style/mixins/media-queries.scss";
@import "../style/mixins/mixin";
@import "../Component/Selecteur/Input/input.scss";
@import "../Component/Icon/baseIcon.scss";
@import "../style/fontSize";
@import "../style/imgSize";


body {
  font-family: Heebo;
  color: var(--second);
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
#modal-root {
  z-index: 1000;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  line-height: initial;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-weight: var(--fw-Black);
}

a {
  text-decoration: none;
  color: var(--text_Principal_Dark);
}

p{
  text-wrap: pretty;
}
input{
  line-height:initial;
}