:root {
    --Accordion-iconColor: var(--first);
    --Accordion-borderColor: var(--background-doc);
    --Accordion-titleColor: var(--text_Principal_Dark);
}

.Accordion {
    border: 1px solid var(--Accordion-borderColor);
    border-radius: 12px;
}

.Accordion {
    border: 1px solid var(--Accordion-borderColor);
    border-radius: 12px;
}

.Accordion_trigger {
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em 2em;
    text-align: inherit;
    width: 100%;
}

.Accordion_title {
    color: var(--Accordion-titleColor);
    font-weight: var(--fw-Bold);
    flex-grow: 1;
}

.Accordion_icon {
    fill: none;
    stroke: var(--Accordion-iconColor);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    transform: rotate(0);
    transition: transform ease 300ms;
}

.Accordion_trigger[aria-expanded="true"] {
    .Accordion_icon {
        transform: rotate(180deg);
    }
}

.Accordion_panel {
    border-top: 1px solid var(--Accordion-borderColor);

    &[aria-hidden="true"] {
        display: none;
    }

}