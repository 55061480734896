.container_SectionLink{
  padding: 6rem 0rem 3rem;
  height: calc(100dvh - var(--PageHeader-height) - var(--Footer-height));
  overflow: scroll;
  gap: 1rem;
}
.textInfo{
  text-align: center;
  color: var(--text_Principal_Dark);
  font-size: var(--fs-ml);
  font-weight: var(--fw-Regular);
  width: 14em;
  text-wrap: pretty;
}
