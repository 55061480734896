:root {
  --FlexRow-width: inherit;
  --FlexRow-direction: column;
  --FlexRow-justifyContent: normal;
  --FlexRow-alignItems: normal;
  --FlexRow-gap: initial;
  --FlexRow-padding: ;
  --FlexRow-wrap: ;
  --FlexRow-grow: ;
}

.FlexRow {
  display: flex;
  width: var(--FlexRow-width);
  flex-direction: var(--FlexRow-direction);
  align-items: var(--FlexRow-alignItems);
  justify-content: var(--FlexRow-justifyContent);
  gap: var(--FlexRow-gap);
  padding: var(--FlexRow-padding);
  flex-wrap: var(--FlexRow-wrap);
  flex-grow: var(--FlexRow-grow);
}
