@import "./../../style/functionCSS";

:root {
  --Modal-textColor: var(--element-textColor);
  --Modal-backgroundColor: var(--element-backgroundColor);
  --Modal-borderColor: var(--border-accent);
  --Modal-subtitleColor: var(--accent);
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: var(--zindex-modal);
  display: none;
}

.Modal-open {
  display: block;
}

.Modal_overlay {
  background: rgba(0, 0, 0, 0.21);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Modal_popup {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  width: 24rem;
  max-width: 80%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid var(--Modal-borderColor);
  border-bottom: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
  color: var(--Modal-textColor);
  background: var(--Modal-backgroundColor);
}

.Modal_closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--cross);
  cursor: pointer;
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.Modal_closeIcon {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.Modal_content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  text-align: center;
}

.Modal_title {
  width: 80%;
  font-size: px-to-rem(20px);
  font-weight: var(--fw-Bold);
}

.Modal_subtitle {
  font-size: px-to-rem(21px);
  font-weight: var(--fw-Bold);
  color: var(--Modal-subtitleColor);
}

.Modal_children {
  font-size: px-to-rem(17px);
  font-weight: var(--fw-Light);
}