.footer_spacer {
  display: block;
  min-height: var(--Footer-height);
}

.footer {
  height: var(--Footer-height);
  position: fixed; //sticky non supporter sur safari et IOS
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px -2px 8px rgb(0 0 0 / 6%);
  background: white;
  z-index: var(--zindex-footer);

  button {
    border: 0rem;
    background: transparent;

    p {
      margin: initial;
    }
  }

  .container_icon {
    display: flex;
    width: 100%;
    max-width: 20rem;
    align-items: center;
    justify-content: center;

    .icon_footer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      // margin-top: 0.3rem;
      .infoNumber {
        position: absolute;
        top: 0rem;
        right: -1rem;
      }
    }
  }

  .photo_user {
    position: absolute;
    right: -4px;
    display: flex;
    align-items: center;

    svg {
      position: absolute;
      right: 0.1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
    }
  }
}
