@import "../../style/functionCSS.scss";
@import "../../style/base/_variables.scss";
@import "../../style/mixins/media-queries.scss";

.container_Header_Retour {
  .container_retour {
    position: relative;
    top: 1rem;
    width: 100%;
    background: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #aec0b7;
    padding: 0.5rem 0rem 0.25rem 2rem;
    box-shadow: none;
    z-index: 1000;
  }
}

.container_Notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  justify-content: center;
  width: fit-content;
  margin: 0.5rem;

  h2 {
    color: #21341c;
    font-size: px-to-rem(29px);
    font-weight: var(--fw-Black);
    text-align: center;
    margin-bottom: px-to-rem(44px);
  }

  .container_legends {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .legend_Notif {
      font-size: px-to-rem(20px);
      color: var(--accent);
      font-weight: var(--fw-Black);
      margin-bottom: 1.5rem;

      @include XS-mobile-S {
        font-size: px-to-rem(18px);
      }
    }

    .edit_Notification {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-size: px-to-rem(16px);
      font-weight: var(--fw-Regular);
      gap: 0.5rem;
      width: inherit;
      color: var(--text_Principal_Dark);
      margin-bottom: var(--marginBottom);

      .ToggleSwitch_label {
        display: flex;
        font-size: px-to-rem(16px);
        font-weight: var(--fw-Regular);
        justify-content: flex-start;
        gap: 0.5rem;
        width: inherit;
        color: var(--text_Principal_Dark);

        @include XS-mobile-S {
          font-size: px-to-rem(16px);
        }
      }
    }

    hr {
      width: 90%;
      height: 0.1px;
      border: none;
      background-color: var(--fourTransparent);
      margin-bottom: var(--marginBottom);
    }
  }
}
