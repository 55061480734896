:root {
  --IconButton-color: ;
}

.IconButton {
  fill: var(--IconButton-color);
  color: var(--IconButton-color);
}

.IconButton-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.IconButton-primary {
  --IconButton-color: var(--first);
}

.IconButton-secondary {
  --IconButton-color: var(--second);
}
.IconButton-third {
  --IconButton-color: var(--third);
}
.IconButton-accent {
  --IconButton-color: var(--accent);
}