.CounterField {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  border: var(--form-borderWidth) solid var(--form-borderColor);
  border-radius: calc(var(--form-borderRadius) * 2);
  width: 8rem;
  height: 6rem;
  color: var(--second);
}

.CounterField_text {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.CounterField_label {
  font-size: 1rem;
  color: var(--form-labelColor);
}

.CounterField_value {
  font-size: 2rem;
  font-weight: bold;
}

.CounterField_buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.CounterField_button {
  margin: 0rem;
  padding: 0rem;
}

.CounterField_icon {
  color: var(--form-labelColor);
  border-radius: 50%;
  border: var(--form-borderWidth) solid currentColor;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 0.2rem;
}