.container_Centre_Notification{
    position: fixed;
    bottom: 0;
    width: 100dvw;
    height: 70dvh;
    background: rgba(255, 255, 255, 0.21);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.container_Buttons_Notification{
    width: 100%;
    height: 55dvh;
    padding: 1rem 0rem;
    overflow-y: scroll;
}
.title_Container_Notification{
    font-size: var(--md);
    font-weight: var(--fw-Bold);
    color: var(--text-Principal-Dark);
    margin-left: 0.5rem;
    
}
.button-close-notification{
    font-size: var(--md);
    font-weight: var(--fw-Medium);
    color: var(--text_Secondaire_Fade);
    text-wrap: nowrap;
}