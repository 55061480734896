.add-cls-1 {
    fill: url(#pattern);
}

.add-cls-2 {
fill: #fff;
}

.add-cls-3 {
fill: #ebbc02;
}