:root {
    --FormFieldset-textColor: var(--t-second);
    --FlexRow-margin: ;
}

.FormFieldset {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin: var(--FlexRow-margin);
}

.FormFieldset_legend {
    color: var(--second);
    font-size: var(--fs-md);
    font-weight: var(--fw-Bold);
    color: var(--FormFieldset-textColor);
}

.FormFieldset_content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.row-wrap{
    justify-content: center;
    flex-direction:row;
    flex-wrap: wrap;
}