@import "../../style/functionCSS";
@import "../../style/base/variables";

.carte_Visite_Conseiller {
  width: 100vw;
  text-align: center;
  color: var(--second);

  .container_Conseiller-detail-bien{
    margin: 1rem 0rem;
  }


  img {
    width: 6rem;
    border-radius: 3rem;
  }

  .prenom,.nom{
    margin: -0.3rem;
    word-break: break-word;
    text-wrap: pretty;
  }

  .buttons_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .conseiller {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      height: 63px;
      width: 225px;
      gap: 1rem;
      border: var(--border-neutre) 2px solid;
      font-size: var(--fs-lg);
      font-weight: var(--fw-Regular);
      

      img {
        width: px-to-rem(45px);
        height: px-to-rem(45px);
        margin: px-to-rem(5px);
      }
      img {
        // width: 2.5rem;
        width: px-to-rem(45px);
        height: px-to-rem(45px);
        margin: px-to-rem(5px);
      }
      p {
        text-align: start;
        font-size: px-to-rem(19px);
        font-weight: var(--fw-Regular);
      }
    }
  }
}
