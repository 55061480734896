@import "../../style/functionCSS";
@import "../../style/mixins/animations";

.header_container {
  position: relative;
  background-color: var(--second);
  height: px-to-rem(59px);
  width: 100dvw;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  box-shadow: var(--boxShadow);

  h2 {
    color: var(--third);
    font-size: px-to-rem(21px);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: normal;
  }
}
.button_close {
  position: absolute;
  right: 2%;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: var(--third);
  font-size: 0.9rem;
  .icon_retour {
    width: 0.7rem;
  }
}
