@import "../../../style/mixins/_media-queries";
@import "../../../style/mixins/animations";

.SearchBar {
  flex-grow: 1;
  z-index: var(--zindex-searchBar);
}

.select {
  font-weight: Bold;
  font-size: 1.188rem;
  color: #ffffff;
  border-radius: 25px;
  width: 10.7rem;
  height: 2.313rem;
  margin-bottom: 1.063rem;
  margin-top: 0.5rem;
}

.SearchBar_submitButton {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 8px;
  display: inline-flex;
  margin: 0;
}

.SearchBar_row {
  flex-wrap: nowrap;
  display: flex;
  position: relative;
}

.SearchBar_input {
  width: 100%;
}

.modal_filter {
  position: absolute;
  top: var(--container_counter_button-height);
  width: 100vw;
}

.isClosing {
  @include slide-right;
  top: var(--container_counter_button-height);
  z-index: 2;
}

._closed {
  display: none;
}

._open {
  display: block;
}

.disabledCard {
  overflow: hidden;
}
