.upload-cls-1 {
    fill: #ebbc02;
  }

.upload-cls-2 {
fill: none;
stroke: #fff;
stroke-linecap: round;
stroke-linejoin: round;
stroke-width: 3px;
}