.IconHouseWithIcon-cls-1 {
  fill:#e4c31f;
  stroke:#e4c31f;
  // strokeMiterlimit:10;
  // strokeWidth:1.498 ;
  }


.IconHouseWithIcon-cls-2 {
  fill:#fff
}