.container_Home_Modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .container_retour {
    top: 0.3rem;
    a {
      color: var(--third);
    }
    .container_TitleGlow {
      button {
        color: var(--first);
      }
    }
  }
}
.Container_Collapse_Decouverte_Update{
  margin-top: 3rem;
}