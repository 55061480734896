@import "../../../style/_functionCSS.scss";

.ToggleSwitch {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.ToggleSwitch_label {
  font-size: px-to-rem(16px);
  font-weight: var(--fw-Regular);
}

.ToggleSwitch_switch {
  position: relative;
  width: 60px;
  height: 34px;
  border: none;
  background: none;
}

.ToggleSwitch_input {
  // position: absolute;
  // top: -99999px;
  // left: -99999px;
  width: 4rem;
  height: 2rem;
  z-index: 1;
  position: relative;
  appearance: unset;

  &:checked {
    + {
      .ToggleSwitch_slider {
        background-color: #aec0b7;

        &:before {
          transform: translateX(26px);
        }
      }
    }
  }

  &:focus {
    + {
      .ToggleSwitch_slider {
        box-shadow: 0 0 1px #2196f3;
      }
    }
  }
}


.ToggleSwitch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
}