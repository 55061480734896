$XS-mobile: 280px;
$S-mobile: 320px;
$M-mobile: 375px;
$L-mobile: 425px;
$Tablet: 768px;
$laptop: 1024px;
$L-laptop: 1440px;
$XL-laptop: 6440px;

/* (min-width: 280px) and (max-width: 319px}) */
@mixin XS-mobile-S {
  @media (min-width: $XS-mobile) and (max-width: #{$S-mobile - 1px}) {
    @content;
  }
}
/* (min-width: 320px) and (max-width: 374px}) */
@mixin S-mobile-M {
  @media (min-width:$S-mobile) and (max-width:#{$M-mobile - 1px} ) {
    @content;
  }
}
/* (min-width: 375px) and (max-width: 424px}) */
@mixin M-mobile-L {
  @media (min-width: $M-mobile) and (max-width:#{$L-mobile - 1px}) {
    @content;
  }
}
/* (min-width: 425px) and (max-width: 767px}) */
@mixin L-mobile-Tablet {
  @media (min-width: $L-mobile ) and (max-width: #{$Tablet - 1px}) {
    @content;
  }
}
/* (min-width: 768px) and (max-width: 1023px}) */
@mixin Tablet-laptop {
  @media (min-width: $Tablet) and (max-width: #{$laptop - 1px}) {
    @content;
  }
}
/* (min-width: 1024px) and (max-width: 1439px}) */
@mixin M-laptop-L {
  @media (min-width: $laptop ) and (max-width: #{$L-laptop - 1px}) {
    @content;
  }
}
/* (min-width: 1440px) and (max-width: 2559px}) */
@mixin L-laptop-XL {
  @media (min-width: $L-laptop) and (max-width: #{$XL-laptop - 1px}) {
    @content;
  }
}
/* (min-width: 2560px) */
@mixin XL-laptop {
  @media (min-width: $XL-laptop) {
    @content;
  }
}

/* (min-width: 768px) and (max-width: 2560px}) */
@mixin Tablet-and-More {
  @media (min-width: $Tablet) and (max-width: $XL-laptop) {
    @content;
  }
}
