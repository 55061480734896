:root {
  --FlexCell-alignSelf: ;
  --FlexRow-justifyContent: ;
  --FlexCell-flexGrow: ;
  --FlexCell-size: ;
}

.FlexCell {
  display: flex;
  align-self: var(--FlexCell-alignSelf);
  justify-content: var(--FlexRow-justifyContent);
  flex-grow: var(--FlexCell-flexGrow);
  width: 100%;
  max-width: var(--FlexCell-size);
}

.FlexCell-xs {
  --FlexCell-size: 4rem;
}

.FlexCell-sm {
  --FlexCell-size: 10rem;
}

.FlexCell-md {
  --FlexCell-size: 16rem;
}

.FlexCell-lg {
  --FlexCell-size: 24rem;
}

.FlexCell-xl {
  --FlexCell-size: 48rem;
}
