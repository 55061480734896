@import "../../style/functionCSS";

.add_Folder {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 13rem;
  height: 2.8rem;
  border: none;
  border-radius: 2rem;
  background: white;
  color: #535353;
  border: #707070 1px solid;
  box-shadow: var(--box-shadow-button);

  p {
    display: flex;
    font-size: px-to-rem(19px);
    font-weight: 500;
  }

  i {
    color: var(--accent);
    padding: 2px;
    margin: 0px 5px;
    font-weight: bolder;
    font-size: 1.6rem;
    width: px-to-rem(30px);
    height: px-to-rem(26px);
  }
}
