@import "../../style/mixins/_media-queries.scss";
@import " ./../../../style/base/variables.scss";
.container_chart_DPE_GES {
  display: flex;
  justify-content: center;
  position: relative;
  .chart_CONSO {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 90%;
    padding: 1rem 0rem 11rem;
    justify-content: center;
    align-items: center;

    .bars {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 0.1rem;
      grid-column: span 7;
      justify-content: space-between;

      .bar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;

        &-letter {
          font-size: 24px;
          font-weight: bold;
          color: white;
        }

        &.conso-A,
        &.conso-B,
        &.conso-C,
        &.conso-D,
        &.conso-E,
        &.conso-F,
        &.conso-G {
          width: 30px;
          border-radius: 10px;
          padding: 0.1rem 0.3rem;
          box-shadow: 0px 3px 6px #00000029;
          &.conso-A {
            background-color: #99d5f6;
          }

          &.conso-B {
            left: calc(20px + var(--input-value) * 10vw);
            background-color: #80abcc;
          }

          &.conso-C {
            left: calc(70px + var(--input-value) * 10vw);
            background-color: #6e87a8;
          }

          &.conso-D {
            left: calc(120vw + var(--input-value) * 10vw);
            background-color: #556485;
          }

          &.conso-E {
            left: calc(170px + var(--input-value) * 10vw);
            background-color: #444866;
          }

          &.conso-F {
            left: calc(230px + var(--input-value) * 10vw);
            background-color: #342e48;
          }

          &.conso-G {
            left: calc(280px + var(--input-value) * 10vw);
            background-color: #251c31;
          }
          &.active {
            transform: scale(1.1);
            margin: 1rem 0.3rem 0rem;
          }
        }
      }
      .cons-conso {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        left: 0px;
        grid-template-rows: auto auto;
        width: 100px;
        grid-column: span 1;
        .value {
          .infoCONSO {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            strong {
              font-size: px-to-rem(17px);
              font-weight: var(--fw-Medium);
            }
            small {
              font-size: px-to-rem(12px);
              display: flex;
              text-align: center;
              width: 100%;
            }
          }
        }

        @include S-mobile-M {
          top: 4rem;
          transform: scale(0.9);
        }
        @include M-mobile-L {
          top: 4rem;
          transform: scale(1);
        }
        @include L-mobile-Tablet {
          top: 4rem;
          transform: scale(1);
        }
        @include Tablet-and-More {
          top: 4rem;
          transform: scale(1);
        }
      }
      .conso-bloc {
        width: 60px;
        height: 50px;
        justify-items: center;
        small {
          display: flex;
          text-align: center;
          width: 100%;
        }

        .point {
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid;
          top: -21px;
          left: 50%;
          transform: translateX(-50%);
          background-color: transparent;
        }

        &.conso-A {
          left: -35%;
        }

        &.conso-B {
          left: -40%;
        }

        &.conso-C {
          left: -40%;
        }

        &.conso-D {
          left: -35%;
        }

        &.conso-E {
          left: -40%;
        }

        &.conso-F {
          left: -40%;
        }

        &.conso-G {
          left: -35%;
        }
      }
    }
  }
  .conso-NC {
    position: absolute;
    top: 5rem;
    left: 40%;
    width: 60px;
    height: 50px;
    justify-items: center;

    small {
      display: flex;
      text-align: center;
      width: 100%;
    }

    .point {
      display: none;
    }
    .infoCONSO {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      strong {
        font-size: px-to-rem(17px);
        font-weight: var(--fw-Medium);
      }
      small {
        font-size: px-to-rem(12px);
        display: flex;
        text-align: center;
        width: 100%;
      }
    }
  }
}
