@import "../../style/functionCSS";
@import "../../style/mixins/media-queries.scss";
:root {
  --select_toggle-height: 3.5rem;
}
#select_toggle {
  display: flex;
  justify-content: flex-start;
  margin: 0rem;
  padding: 0rem;
  height: var(--select_toggle-height);
  width: 100vw;
  background: var(--third);
  box-shadow: var(--box-shadow);
  overflow: auto;
  .button-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  a {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 10rem;
    margin: 1rem;
    scroll-snap-align: center;

    p {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: var(--text_Secondaire_Fade);
      font-weight: 300;
      font-size: px-to-rem(23px);
      margin: 0rem;
      width: max-content;
    }

    @media screen and (max-width: 320px) {
      p {
        font-size: px-to-rem(19px);
      }
    }
  }
  a:has(p.activeTitle) {
    display: flex;
    flex: 1;
  }

  .containerMaSelection {
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: max-content;
    text-wrap: nowrap;
    color: var(--text_Secondaire_Fade);

    .maSelection {
      display: flex;
      flex-direction: column;
    }
    .infoNumber {
      position: absolute;
      top: -5%;
      left: -5%;
      transform: translate(-50%, -50%);
    }
  }

  .activeTitle {
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    color: var(--second);
    text-align: center;
    font-weight: var(--fw-ExtraBold);
    font-size: px-to-rem(23px);

    &:after {
      position: absolute;
      bottom: -0.9rem;
      content: " ";
      width: 50%;
      height: 0.25rem;
      background-color: var(--first);
    }
  }
}
