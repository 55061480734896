@import "../../style/functionCSS";

.container_Dropdown {
  width: 100vw;
  background: var(--bc_Card);
  padding: 0.5rem;

  .container_Title_Icone_Collapse {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: var(--text_Principal_Dark);
      margin: 0.5rem;
    }
  }
}

.collapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;  
  overflow-x: hidden;
  background: var(--bc_Card);

  .collapse-title {
    text-wrap: nowrap;
    color: rgb(83, 83, 83);
    display: flex;
    flex-direction: column;
    width: inherit;
    align-items: center;
    text-align: center;
    justify-content: space-around;

    h2 {
      margin: px-to-rem(8px) px-to-rem(0px);
      font-weight: 600;
    }

    i {
      margin: px-to-rem(0px) px-to-rem(0px) px-to-rem(8px);
      font-weight: 900;
    }
    &:has(.fa-chevron-up) {
      i {
        color: var(--first);
      }
    }
  }
}

.collapse-content_closed {
  display: none;
}

.collapse-content_open {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: var(--box-shadow-button);
  margin: 1rem;

  li {
    text-indent: 0rem;
    padding-bottom: 0.3rem;
  }
}

.collapse-content-array_closed {
  display: none;
}

.collapse-content-array_open {
  display: grid;
  justify-content: center;
  grid-template-columns: 9rem 9rem;
  padding: 1rem;
  row-gap: 1rem;
  color: var(--text_Principal_Dark);

  .info_Bien {
    padding: 0rem 1rem;
    margin: 0rem -0.5rem; // don't use nowrap to avoid merging text
  }

  ul {
    padding: 0rem;

    .element_title {
      margin-bottom: 0.2rem;
      font-size: 1rem;
      font-weight: bold;
      list-style: none;
    }

    .element_content {
      list-style: none;
      margin-bottom: 0.2rem;
      
    }
  }
}
