.fs-xs{
font-size: var(--fs-xs);
}
.fs-md{
font-size: var(--fs-md);
}
.fs-lg{
    font-size: var(--fs-lg);
}
.fs-xl{
    font-size: var(--fs-xl);
}
.fs-xxl{
    font-size: var(--fs-xxl);
}
.fs-3xl{
    font-size: var(--fs-3xl);
}