@import "../../style/functionCSS.scss";
@import "../../style/base/_variables.scss";
@import "../../style/mixins/media-queries.scss";

.buttons_Projets {

  .container_button_partenaire,
  .container_button_add {
    button,
    a,
    .profil_add {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--third);
      border: var(--border-accent) 1px solid;
      border-bottom: none;
      color: var(--second);
      box-shadow: var(--box-shadow);

      &:first-child {
        display: flex;
        justify-content: center;
        padding: 2rem 0.5rem 0rem;
        align-items: center;
        width: px-to-rem(164px);
        height: px-to-rem(120px);
        border-radius: 10px 10px 0px 0px;

        img {
          width: px-to-rem(77px);
          height: px-to-rem(77px);
          border-radius: 50%;
          margin-bottom: 0.5rem;
          position: absolute;
          top: -2rem;
          z-index: 1;
        }

        i {
          position: absolute;
          border-radius: 50%;
          width: px-to-rem(30px);
          height: px-to-rem(30px);
          display: flex;
          justify-content: center;
          align-items: center;
          top: -20px;
          outline: 3px solid var(--border-accent);
          background-color: white;
          font-size: px-to-rem(26px);
          color: var(--accent);
          padding: 0.25rem;
        }

        .identity_profil,
        .add_acquereur {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0.5rem 0rem;
          align-items: center;
          width: 100%;

          p {
            text-align: center;
            font-size: 0.8rem;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            width: 100%;
          }

          .firstName {
            word-break: break-all;
            font-size: px-to-rem(25px);
            font-weight: var(--fw-Light);
          }

          .lastName {
            font-size: px-to-rem(25px);
            font-weight: var(--fw-Bold);
          }

          .subtitle {
            font-size: px-to-rem(17px);
            font-weight: var(--fw-Regular);
            color: var(--accent);
          }
        }
      }
    }

    .profil_add {
      padding-bottom: 1rem;
    }

    .editing {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      background-color: var(--second);
      border-radius: 0px 0px 10px 10px;
      width: inherit;
      padding: 0.3rem 0rem;
      color: var(--third);
      height: px-to-rem(50px);
      border: var(--second) 1px solid;
      border-top: none;

      i {
        font-size: px-to-rem(23px);
      }

      p {
        margin: 0rem;
        font-size: px-to-rem(23px);
        font-weight: var(--fw-Regular);
      }
    }

    .noEditing {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      background-color: var(--accent);
      border-radius: 0px 0px 10px 10px;
      width: inherit;
      height: px-to-rem(50px);
      padding: 0.3rem 0rem;
      color: var(--third);
      border: var(--border-accent) 1px solid;
      border-top: none;
    }
  }

  .container_button_partenaire:hover,
  .container_button_add:hover {
    transform: scale(1.15);
  }

  .container_Modal_Glow {
    .modal-content {
      gap: 1rem;

      .text-content-button {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .label_group {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .info {
          color: var(--second);
          font-weight: var(--fw-Regular);
        }

        input {
          font-size: px-to-rem(15px);
          font-weight: var(--fw-Light);
          color: var(--second);
          height: 1.5rem;
          width: 10rem;
          border: none;
          border-radius: px-to-rem(5px);
          background: white;
          border-bottom: var(--border-neutre) 1px solid;
          padding: 0rem 0.5rem;
        }

        input:placeholder {
          color: red;
        }

        input:placeholder-shown {
          color: white;
        }
      }
    }
  }
}
