@import "../functionCSS";

:root {
  overflow-x: hidden;
  /* Couleur */
  --first: #ebbc02;
  --firstTransparent: #e8ba0229;
  --second: #535353;
  --secondTransparent: #53535323;
  --third: #ffffff;
  --thirdTransparent: #ffffff29;
  --four: #aec0b7;
  --fourTransparent: #aec0b74d;
  --five: #dfdfdf;
  --fiveTransparent: #dfdfdf50;
  --six:  #737373; // #c1c1c1;
  --sixTransparent: #73737352; // #c1c1c154;
  --seven: #f8f8f8;
  --sevenTransparent: #f8f8f858;
  --eight: #21341C;
  --eightTransparent: #21341c52;
  --nine:  #c1c1c1;
  --nineTransparent: #c1c1c154;
  --ten:  #333333;
  --nineTransparent: #33333354;

  
  /* Text Color */
  --text_Principal_Dark: var(--second);
  --text_Principal_Light: var(--third);
  --text_Secondaire_Fade: var(--six);
  --text-Principal_Dark-Disabled: var(--secondTransparent);
  --text-Principal_Light-Disabled: var(--thirdTransparent);
  
  
  --accent: var(--four);
  --not-valid: var(--five);
  --background-doc: var(--five);
  --cross: var(--five);
  --border-neutre: var(--nine);
  --border-accent: var(--four);
  --border-disabled:  var(--disabled);

  --logo_Notification: var(--third);
  --logo_SecondaireNotification: var(--second);
  --logo_Icon_Light: var(--third);
  --logo_Icon_Dark: var(--six);
  --logo_Footer: #0000000f;

  --bc_Card: var(--seven);
  --bc_icon_doc: var(--seven);

  --alerte: #ff0f0f;
  --disabled: #f6f6f6;
  --element-textColor: var(--height);
  --element-textColor-hsl: 107 30% 16%;
  --element-backgroundColor: #ffffff;
  --element-backgroundColor-hsl: 0 0% 100%;
  --element-textColor-reverse: #ffffff;
  --element-backgroundColor-reverse: var(--eight);

  --form-textColor: var(--ten);
  --form-primaryColor: var(--first);
  --form-labelColor: var(--text_Secondaire_Fade);
  --form-placeholderColor: var(--text_Secondaire_Fade);
  --form-backgroundColor: #ffffff;
  --form-borderColor: #c1c1c1;
  --form-borderWidth: 1px;
  --form-borderRadius: 9px;
  --form-outlineWidth: 2px;
  --form-outlineColor: #5e9ed6;
  --form-invalidColor: var(--alerte);
  --form-textColor-disabled: var(--disabled);
  --form-border-disabled:  var(--disabled);
  --form-backgroundColor-disabled:  var(--disabled);
  --form-baseHeight: #{px-to-rem(36px)};
  --form-paddingY: 0.33em;
  --form-paddingX: 1em;

  /* Typographie */
  --base-font-family: "Heebo";
  --base-font-size: 16px;
  --line-height: 1.5;
  --base-rem: 16px;

  --fs-xs: 0.6rem; // 9.6px
  --fs-sm: 0.8rem; // 12.8px
  --fs-md: 1rem; // 16px
  --fs-ml: 1.18rem; // 19px
  --fs-lg: 1.3rem; // 20.8px
  --fs-xl: 1.5rem; // 24px
  --fs-xxl: 2.5rem; // 40px
  --fs-3xl: 3.375rem; // 54px
  
    /* size-image */
  --img-xs: 0.6rem; // 9.6px
  --img-sm: 0.8rem; // 12.8px
  --img-md: 1rem; // 16px
  --img-lg: 1.3rem; // 20.8px
  --img-xl: 1.5rem; // 24px
  --img-xxl: 2.25rem; // 36px
  --img-3xl: 2.5rem; // 40px
  --img-4xl: 3.375rem; // 54px
  --img-5xl: 5.93rem; // 95px
  
  /* font-weight */
  --fw-Thin: 100;
  --fw-ExtraLight: 200;
  --fw-Light: 300;
  --fw-Regular: 400;
  --fw-Medium: 500;
  --fw-SemiBold: 600;
  --fw-Bold: 700;
  --fw-ExtraBold: 800;
  --fw-Black: 900;
  --fw-ExtraBlack: 950;

  /* Ombre */
  /* --box-shadow: 0rem 0.2rem 0.3rem rgba(0, 0, 0, 0.2); // ancien*/
  --box-shadow: 0px 12px 20px -12px rgba(0, 0, 0, 0.35);
  --box-shadow-button: 0px 3px 6px rgba(0, 0, 0, 0.161);
  --box-shadow-big: 0px 3px 6px rgba(0, 0, 0, 0.161);

  /* Espacements */
  --margin: 20px;
  --marginBottom: 1.438rem;
  --padding: 15px;

  /* Profondeur */
  --zindex-modal: 1010;
  --zindex-toolbar: 1005;
  --zindex-searchBar: 1001;
  --zindex-footer: 1000;

  /* page */
  --PageHeader-height: 8rem;
  --Footer-height: 5rem;

}
// * {
//   border: red 2px solid;
// }
