@import "../../../../style/functionCSS.scss";
@import "../../../../style/base/_variables.scss";

.options_container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 2fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: inherit;

  .info_option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    .icon_option {
      width: px-to-rem(40px);
    }

    div {
      font-size: px-to-rem(14px);
      color: var(--second);
      font-weight: 800;
    }
  }
}

.boutons_checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3.5px;
    gap: 0.5rem;

    input[type="checkbox"] {
      appearance: none;
      background-color: white;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 2px solid var(--border-neutre);
    }

    input[type="checkbox"]:checked {
      background-color: var(--first);
    }

    label {
      flex: 1;
      font-size: px-to-rem(24px);
      color: var(--second);
      font-weight: 800;
    }
  }

  input {
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    appearance: none;
    font-size: px-to-rem(15px);
    font-weight: var(--fw-Light);
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid var(--first);
      border-radius: 3rem;
      padding-left: 0.5rem;
      height: 2rem;
    }

    &:not {
      border: 2px solid var(--first) !important;
    }
  }
}
