@import "./../../../style//functionCSS";
@import "./../../../style//base/variables";

.container_Modal_Glow {
  position: fixed;
  top: 35dvh;
  left: 50vw;
  transform: translate(-50%, 0%);
  width: 90%;
  height: px-to-rem(148px);
  z-index: 1000;

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: px-to-rem(16px);
    border-radius: 19px;
    background: rgba(0, 0, 0, 0.21);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.169);
    text-align: center;
    color: var(--third);
    min-height: 178px;
    gap: 1rem;
    .buttons_Modal {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .close-button {
        color: var(--cross);
        width: px-to-rem(43px);
        position: relative;
        right: -4%;
      }

      .open-button {
        color: var(--cross);
        margin: 0rem;
        // width: px-to-rem(54px);
      }
    }

    .text-content-button {
      p {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0rem;
        color: var(--third);
        min-width: px-to-rem(173px);
      }

      

      .underTitle {
        font-size: px-to-rem(19px);
        font-weight: 500;
        margin-bottom: px-to-rem(8px);
      }

      .children {
        font-size: px-to-rem(17px);
        font-weight: var(--fw-Light);
        white-space: pre-wrap;
      }
    }
  }
}
.title_Modal_Glow {
  font-size: px-to-rem(27px);
  font-weight: 500;
  width: 70%;
}
.close {
  display: none;
}
