@import "../../style/functionCSS";
@import "../../style/mixins/media-queries.scss";
@import "../../style/mixins/_animations.scss";

:root {
  --container_counter_button-height:5rem;
}

.loader {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerChoice{
  position: fixed;
  z-index: 1;
}
.container_counter_button_title{
  display: contents;
  @include slide-down;
}
._openHalf {
  @include slide-half-down;
}
._closedHalf {
  @include slide-half-up;
}



#immo_container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  justify-content: flex-start;

  .containerToggleAchatLocation{
    padding: 1.5rem 0rem 0.5rem  ;
  }

  .search_toggle_container {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }

  .container_counter_button_title {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    padding: 0.5em;
    box-sizing: border-box;
    width: 100%;
    max-width: 40rem;
    height: var(--container_counter_button-height);
    align-content: center;
    align-items: center;
    justify-content: space-around;

    @include M-mobile-L {
      flex-direction: row;
      // margin: 1rem 0rem;
    }

    @include L-mobile-Tablet {
      flex-direction: row;
      // margin: 1rem 0rem;
    }

    @include Tablet-and-More {
      flex-direction: row;
      // margin: 1rem 0rem;
    }

    .property_counter {
      display: flex;
      // flex: 1;
      flex-direction: row;
      justify-content: flex-start;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: px-to-rem(5px);
        background: var(--background-doc);
        width: 55px;
        font-size: px-to-rem(13px);
        font-weight: 400;
        color: var(--second);
        margin: 0;
        padding: 0.25em 0;
        text-align: center;
      }
    }

    .button_title {
      flex: 0.5;
      background-color: var(--first);
      border-radius: 2.18rem;
      color: var(--third);
      font-weight: bold;
      border: none;
      margin: 1rem 1rem;

      p {
        font-size: px-to-rem(19px);

        padding: px-to-rem(5px) px-to-rem(9px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      font-weight: bold;
      border: none;

      &:hover {
        @include lowerScale;
      }
    }

    .search_immo {
      background: white;
      flex: 4;
      height: 3rem;
      width: 90%;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 0rem 0.5rem;
      margin: 0.65rem 0rem;

      @include M-mobile-L {
        width: 5rem;
      }

      @include L-mobile-Tablet {
        width: 5rem;
      }

      @include Tablet-and-More {
        width: 5rem;
      }

      input {
        height: 2rem;
        width: 100%;
        font-family: Heebo;
        color: var(--accent);
        border: none;
        flex: 1;

        &:focus-visible {
          outline: 2px solid transparent;
        }

        &::placeholder {
          color: var(--not-valid);
          font-weight: 300;
          font-size: px-to-rem(20px);
        }

        @include M-mobile-L {
          height: 2rem;
        }

        @include L-mobile-Tablet {
          height: 2rem;
        }

        @include Tablet-and-More {
          height: 2rem;
        }
      }

      [type="search"] {
        outline-offset: -2px;
      }

      button {
        border: none;
        background: transparent;

        img {
          width: 1.5rem;
        }
      }
    }
  }
}

.cards_immos {
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 16rem;
  padding: 0rem 0rem 1rem;
  width: 100dvw;
  height: 100dvh;

  .containerNothingFind {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      flex: 1;
      margin: 1rem;
      text-align: center;
      font-size: 1.2rem; //Sous la demande de Vincent, il fallait grossir le texte
      font-weight: var(--fw-Light);
      color: var(--text_Principal_Dark);
    }

    img {
      height: 12rem;
    }
  }

  &:has(.button) {
    gap: 1rem;

    .medium {
      min-height: 80px;
      min-width: 154px;
      margin: 2rem;

      img {
        top: -32%;
      }
    }
  }
  }
._moreSlide{
  @include slide-card-immo-up;
  margin-top: 8rem;
}
._lessSlide{
  @include slide-card-immo-reset;
  margin-top: 16rem;
}
._disabled {
  display: none;
}