@import "../../style/functionCSS.scss";
@import "../../style/mixins/media-queries.scss";
@import "../../style/base/_variables.scss";

.icon_house_HomeVente{
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.container_Home_Vente {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - var(--Footer-height));
  margin-top: 1rem;
  gap: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;
  .container_ButtonsDragonfly {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 32rem;
    scale: 0.8;

    @include S-mobile-M {
      scale: 0.9;
    }
    @include M-mobile-L {
      scale: 0.9;
    }

    @include L-mobile-Tablet {
      scale: 0.9;
    }

    @include Tablet-and-More {
      scale: 0.9;
    }
  }

  .medium {
    position: relative;
    height: initial;
    width: initial;
    font-size: px-to-rem(30px);
    font-weight: var(--fw-Black);
    padding: 1.5rem;
    height: 100%;
    min-height: px-to-rem(24px);
    max-height: px-to-rem(35px);
    scale: 0.8;

    @include M-mobile-L {
      scale: 1;
    }

    @include L-mobile-Tablet {
      scale: 0.9;
    }

    @include Tablet-and-More {
      scale: 0.9;
    }

    img {
      position: absolute;
      top: -1.7rem;
    }
  }

  .small-light {
    color: var(--text_Secondaire_Fade);
  }
}
