@import "../../../style/functionCSS.scss";
@import "../../../style/base/_variables.scss";
@import "../../../style/mixins/animations.scss";

.container_Decouverte_1 {
  height: max-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 13rem 0rem 0rem;

  .titre {
    margin: 2.5rem;

    h1 {
      align-items: center;
      display: flex;
      flex-direction: column;

      .p_Second {
        color: var(--second);
        font-size: px-to-rem(40px);
        font-weight: var(--fw-Black);
        text-align: center;
      }

      .p_First {
        color: var(--first);
        font-size: px-to-rem(62px);
        font-weight: var(--fw-Black);
        margin-top: -0.9rem;
        text-align: center;
      }

      .container_Name_Project {
        display: flex;
        width: auto;
        background: var(--bc-input);
        padding: 0.2rem 1rem;

        input {
          text-align: center;
          background: transparent;
        }

        i {
          color: var(--accent);
        }
      }
    }
  }

  .buttons_Membres {
    display: flex;
    width: 100vw;
    overflow: visible;
    justify-content: center;
  }

  .button {
    margin-bottom: 5rem;
  }
}