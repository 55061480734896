@import "../../../../style/functionCSS";

.boutons_checkbox_number {
  display: flex;
  flex-direction: column;

  //   width: 9.5rem;
  h3 {
    font-weight: 400;
    font-size: px-to-rem(17px);
    margin: 0rem;
    color: var(--second);
    text-align: center;
  }

  .numbers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3.5px;
    gap: 0.5rem;
    position: relative;

    input[type="checkbox"] {
      appearance: none;
      width: px-to-rem(35.2px);
      height: px-to-rem(35.2px);
      border-radius: 50%;
      border: 5px solid var(--first);
    }

    input[type="checkbox"]:checked {
      background-color: var(--first);
    }

    label {
      position: absolute;
      top: 50%;
      left: 40%;
      font-size: px-to-rem(20.9px);
      font-weight: 800;
      color: var(--second);
      transform: translate(-15%, -45%);
    }
  }
}
