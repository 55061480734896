@import "../../style/functionCSS";
@import "../../style/mixins/_media-queries.scss";
@import "../../style/mixins/animations";


.modal_aside {
  position: fixed;
  height: 100dvh;
  @include slide-left;
  z-index: 2;
  background: white;
  // top: calc(
  //   1rem + var(--container_counter_button-height) + var(--select_toggle-height)
  // );
  // @include M-mobile-L {
  //   top: calc(
  //     -0.5rem + var(--container_counter_button-height) + var(--select_toggle-height)
  //   );
  // }

  // @include L-mobile-Tablet {
  //   top: calc(
  //     -0.5rem + var(--container_counter_button-height) + var(--select_toggle-height)
  //   );
  // }

  // @include Tablet-and-More {
  //   top: calc(
  //     -0.5rem + var(--container_counter_button-height) + var(--select_toggle-height)
  //   );
  // }

  .form_Filter {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    overflow: auto;
    background: white;
  }

  .form_Filter_closedHalf {
    @extend .form_Filter;
    height: calc(
      100dvh - var(--Footer-height) - var(--select_toggle-height) -
        var(--container_counter_button-height)
    );

    @include L-laptop-XL {
      height: calc(
        100dvh - var(--Footer-height) - var(--select_toggle-height) -
          var(--container_counter_button-height)
      );
    }

    @include XL-laptop {
      height: calc(
        100dvh - var(--Footer-height) - var(--select_toggle-height) -
          var(--container_counter_button-height)
      );
    }
  }

  .form_Filter_openHalf {
    
    @extend .form_Filter;
    height: calc(
      80dvh - var(--Footer-height) - var(--select_toggle-height) -
        var(--container_counter_button-height)
    );

    @include L-laptop-XL {
      height: calc(
        90dvh - var(--Footer-height) - var(--select_toggle-height) -
          var(--container_counter_button-height)
      );
    }

    @include XL-laptop {
      height: calc(
        90dvh - var(--Footer-height) - var(--select_toggle-height) -
          var(--container_counter_button-height)
      );
    }
  }

  .filters_container {
    display: flex;
    overflow: scroll;
    flex-direction: column;
    position: relative;
    background-color: var(--third);
    width: 100vw;
    height: 65dvh;
    padding-bottom: 5rem;

    @include L-laptop-XL {
      height: 78dvh;
    }

    @include XL-laptop {
      height: 78dvh;
    }

    .container_Info {
      position: relative;
      margin: 1rem;
      width: 100%;
    }

    hr {
      width: 70%;
    }

    .filter_container {
      display: flex;
      width: 90vw;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
    }

    .option_container {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }

  button {
    margin: 2rem 0rem 4rem;
  }
}
