@import "../../style/base/variables";
@import "../../style/functionCSS.scss";
@import "../../style/mixins/_media-queries";

._title_Data_Info_Achat {
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.container_Data_Info_Achat {
  margin-bottom: 5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: inherit;

  .container_Header_Retour {
    width: 100%;
    .container_retour {
      top: 0.3rem;

      a {
        color: var(--third);
      }

      button {
        color: var(--first);
      }
    }
  }

  .container_Cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @include Tablet-and-More {
      grid-template-columns: repeat(2, 1fr);
    }
    @include M-laptop-L {
      grid-template-columns: repeat(3, 1fr);
    }
    @include L-laptop-XL {
      grid-template-columns: repeat(3, 1fr);
    }
    @include XL-laptop {
      grid-template-columns: repeat(4, 1fr);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
    }
  }
}
