@import "../../style//mixins/animations.scss";

.cls-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: transparent;
  stroke: #1c2c18;
  stroke-width:3;
  transition: 0.5s ease-in-out;
}
// .cls-1 {
  //   fill: none;
  //   stroke: #1c2c18;
  //   stroke-width: 2px;
  // }
  
  .favori {
    fill: var(--first);
    stroke: var(--first);
    transition: 0.5s ease-in-out;
}

