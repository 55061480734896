@import "../../../style/functionCSS.scss";

.GoogleLogin {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  gap: 1em;
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 1.063rem;
  color: var(--text_Principal_Dark);
  font-weight: var(--fw-Light);
  height: var(--form-baseHeight);
  border-radius: calc(var(--form-baseHeight) / 2);
  background: var(--form-backgroundColor);
  border: 1px solid;
}