@import "../../../style/base/variables";
@import "../../../style/functionCSS.scss";
@import "../../../style/mixins/_media-queries";

.data_card_container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 270px;
  width: 100%;
  margin: 0rem 1rem;
  border: 1px solid var(--border-neutre);
  border-radius: 8px 8px 0px;
  overflow: hidden;
  white-space: nowrap;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.05rem;
    width: 100%;
  }
  .data_card_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem;
    .data_card_title {
      font-size: px-to-rem(19px);
      font-weight: var(--fw-Light);
      color: var(--text_Principal_Dark);
    }
    .data_card_location {
      display: flex;
      flex-direction: column;
      width: 100%;
      .data_card_ville {
        color: var(--text_Principal_Dark);
        font-size: px-to-rem(16px);
        font-weight: var(--fw-Bold);
      }
      .data_card_postalCode {
        color: var(--text_Principal_Dark);
        font-size: px-to-rem(16px);
        font-weight: var(--fw-Light);
      }
    }
    .data_card_price {
      width: 100%;
      text-align: end;
      color: var(--text_Principal_Dark);
      font-size: px-to-rem(19px);
      font-weight: var(--fw-Black);
      flex: 1;
    }
  }
  img {
    height: 10rem;
    object-fit: cover;
  }
}
