.section-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color:var(--element-backgroundColor);
    border: 0.5px solid var(--border-neutre);
    border-radius: 8px;
    text-decoration: none;
  }
  
  .section-button-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80dvw;
    max-width: 362px;
  }
  
  .section-button-label {
    font-size: var(--fs-ml);
    font-weight: var(--fw-ExtraBold);
    color: var(--text_Principal_Dark);
  }
  
  .section-button-icon {
    color: var(--logo_Icon_Dark);
    font-size: var(--fs-ml);
  }