@import "./../../style/functionCSS";

.container_Documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;

  .titre {
    h2 {
      display: flex;
      flex-direction: column;

      .p_Second {
        color: var(--second);
        width: 7.7rem;
        font-size: px-to-rem(64px);
        font-weight: 900;
        text-align: center;
      }

      .p_First {
        color: var(--first);
        width: 7.7rem;
        font-size: px-to-rem(39px);
        margin-top: -0.9rem;
        font-weight: 900;
        text-align: center;
      }
    }
  }

  .buttons_Projets {
    overflow: scroll;
    display: flex;
    height: 21rem;
    padding: 3rem 20vw; // test
    width: 60vw;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
  }

  .projet_card {
    // margin: 1rem;
    box-shadow: 0 1px 1rem -12px #000;
    margin: 1rem;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    opacity: 0.5;
    /* Par exemple, réduire l'opacité */

    &:hover {
      transform: translateY(-3px);
      opacity: 1;
    }
  }
}
