@import "../../../style/functionCSS.scss";
@import "../../../style/base/variables.scss";

.container_Modal_Bottom {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(104, 99, 99, 0.69);
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 20000;
  /* display: none; */
  align-items: flex-end;
  justify-content: center;

  .modal_Bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    border: #aec0b7 1px solid;
    background-color: white;
    border-radius: 35px 35px 0px 0px;
    box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 16%);

    .container_Title {
      display: flex;
      position: relative;
      display: flex;
      justify-content: center;
      background: var(--accent);
      width: 100%;
      text-align: center;
      margin: 0rem;
      padding: px-to-rem(9px) px-to-rem(51px);
      align-items: center;

      p {
        flex: 2 1 auto;
        font-size: px-to-rem(21px);
        display: flex;
        color: var(--third);
        font-weight: var(--fw-Black);
        justify-content: center;
        margin: 0rem;
      }

      .return {
        flex: 1 1 auto;
        color: var(--third);
      }
    }

    .bloc_Button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 17rem;
      margin: 0.5rem 0rem;
      gap: 1rem;
      background: none;
      border: none;
      font-size: var(--fs-md);
      font-weight: var(--fw-Medium);
      color: var(--text_Principal_Dark);

      img {
        margin-right: 1rem;
        height: 1.8rem;
      }
    }
  }
}