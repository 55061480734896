.container_Decouverte_2 {
  width: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .container_members {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    gap: 1rem;
  }

  .form_decouvert {
    display: flex;
    align-items: center;
    margin-bottom: 4.3rem;

    .container_principal_form_decouvert {
      border: var(--border-accent) solid 1px;
      border-radius: 35px;
      width: 100%;
      min-height: 5rem;
      height: max-content;
      margin: 0rem;

      .entete_form_decouvert {
        border-radius: 30px 30px 0px 0px;
        background: var(--accent);
        color: white;
        text-align: center;
        padding: 0.5rem 1rem;
      }

      .label_group {
        margin: 1rem;

        label {
          height: max-content;
          margin: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }
      }

      .container_submit {
        padding: 0rem;
        margin-bottom: 5rem;

        .alerte {
          color: var(--first);
          font-size: 1.2rem;
          padding: 1rem;
          margin: 0rem;
        }
      }

      .typeEtablissementScolaire {
        color: var(--second);
        font-weight: 300;
      }
    }

    .container_buttons {
      padding: 2rem 0rem;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 5rem;

      .button_back {
        width: 100%;

        button {
          font-size: px-to-rem(17px);
          font-weight: var(--fw-Bold);
          color: var(--accent);
          display: flex;
          gap: 0.5rem;
        }
      }

      .container_submit {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        margin: 0rem;

        button {
          width: 7rem;
        }
      }
    }
  }
}

.container_Header_Retour {
  .container_retour {
    position: absolute;
    top: 2.5rem;
    left: -1rem;
    width: inherit;
    background: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #aec0b7;
    padding: 0.5rem 0rem 0.25rem 2rem;
    box-shadow: none;
    z-index: 1000;
  }
}

.disabled {
  overflow: hidden;
}